<owc-modal-dialog #modalDialogElement [(ngModel)]="visible" [cardProps]="{ elevated: true }">
    <owc-progress-bar #progressBarElement  [value]="50" *ngIf="loading" style="width: 100%;padding-bottom: 10px;" />
        <div class="flex-row">
            <div class="col-12 mlr-24 " style="display: flex;" id="confirmBody">
                <p> Are you sure  you want to delete <b>" {{ this.data?.entitlements?.entitlement}}" </b> license
                    for the laboratory <b>"{{data.labName}} ( {{data.countryCode}} : {{data.labId}} )" </b>?</p>
            </div>
        </div>
    <owc-component-header slot="header" id="header">Delete license</owc-component-header>

    <owc-component-footer slot="footer" style="display: block">
        <div slot="actions"  [style]="{ display: 'flex', justifyContent: 'flex-end' }" >
          <owc-button   variant="secondary" [style]="{ 'margin-left': '12px' }"   flat (click)="closePopup()"
            id="close-btn" >Cancel</owc-button>
    
          <owc-button [style]="{ 'margin-left': '12px' }" flat id="submit-btn"  (click)="deleteLicense()"> Delete </owc-button>
        </div>
      </owc-component-footer>
  </owc-modal-dialog>
