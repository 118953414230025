import { provideHttpClient } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { OneWebComponentsAngularModule } from '@one/angular';
import { environment } from '../environments/environment';
import { RouterModule } from '@angular/router';
import { DatePipe } from '@angular/common'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appInitializerProvider } from './core/app-initializer/app-initializer.service';
import { ENVIRONMENT } from './core/models/environment.token';
import { FormsModule } from '@angular/forms';
import { FooterComponent } from './features/app-enabler/footer/footer-component/footer.component';
import { DoubleGlobalAreaComponent } from './features/app-enabler/header/double-global-area/double-global-area.component';
import { SampleFeatureModule } from './features/sample-feature/sample-feature.module';
import { StopAutofocusDirective } from './features/services/stop-auto-focus.directive';
@NgModule({
  declarations: [AppComponent, StopAutofocusDirective],
  imports: [
    RouterModule,
    DoubleGlobalAreaComponent,
    FooterComponent,
    BrowserModule,
    AppRoutingModule,
    OneWebComponentsAngularModule,
    FormsModule,
    SampleFeatureModule
  ],
  providers: [
    appInitializerProvider,
    ...environment.providers,
    { provide: ENVIRONMENT, useValue: environment },
    provideHttpClient(),DatePipe
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
