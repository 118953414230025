import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {

    if (req.headers.has('Authorization')) {
      return next.handle(req);
    }

    let bearerToken = '';
    if (req.url.indexOf('/api/v1/laboratories') > -1) {
      bearerToken = localStorage['token'];
    }

    const modifiedReq = req.clone({
      setHeaders: {
        Authorization: 'Bearer '+ bearerToken,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        test: '*',
      },
    });

    return next.handle(modifiedReq);
  }
}
