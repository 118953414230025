import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Components } from '@one/web-components';
import { Router } from '@angular/router';
import { FeatureService } from '../../../services/feature.service';
import { Subject, Subscription } from 'rxjs';
import { AddModalComponent } from '../common/add-modal/add-modal.component';
import { MatDialog } from '@angular/material/dialog';
import $ from 'jquery';
import { debounceTime, take } from 'rxjs/operators';
import { ConfirmModalComponent } from '../common/confirm-modal/confirm-modal.component';

@Component({
  selector: 'dl-app-admin',
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss',
})
export class AdminComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('iconElement', { static: true }) private iconElement:
    | Components.OwcIcon
    | undefined;
  @ViewChild('buttonElement', { static: true })
  private buttonElement!: Components.OwcButton;
  @ViewChild('tableElement', { static: true })
  tableElement!: Components.OwcTable;
  @ViewChild('paginationElement', { static: true })
  public paginationElement!: Components.OwcPagination; // Add initializer here
  public subscription: Subscription[] = [];
  public laboratories: any[] = [];
  public page: number | undefined;
  public total: number | undefined;
  public rowsOptions: any;
  public rowsPerPage: number | undefined;
  public paginatedLaboratoriesData: any[] = [];
  public loading = false;
  public searchTerm = '';
  private debounceTimeMs = 1000;
  private searchSubject = new Subject<any>();
  public allLaboratoriesData: any[] = [];
  constructor(
    private router: Router,
    private featureService: FeatureService,
    private ref: ChangeDetectorRef,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
  ) {
    this.tableElement = {} as Components.OwcTable;
    this.paginationElement = {} as Components.OwcPagination;
  }

  public ngOnInit(): void {
    console.log('AdminComponent ngOnInit');
    this.loading = true;
    this.page = 1;
    this.rowsPerPage = 10;
    this.total = 1;
    this.rowsOptions = [10, 20, 25];
    this.getLaboratoriesAndLicensesFromDB();
    this.searchSubject.pipe(debounceTime(this.debounceTimeMs)).subscribe(ss => {
      this.paginatedLaboratoriesData = this.getPaginatedLaboratories();
      this.cdr.detectChanges();
    });
  }

  public ngAfterViewInit(): void {
    let controls;
    setTimeout(() => {
      controls = $('#tabsElement').find('owc-tab');
      $(controls[0]).trigger('click');
      this.ref.markForCheck();
    }, 1000);
  }

  public getLaboratoriesAndLicensesFromDB(): void {
    let url = '';
    this.allLaboratoriesData = [];
    url = `${this.featureService.licenseUrl}/laboratories/`;
    this.subscription.push(
      this.featureService.get(url).subscribe(
        (response: any) => {
          if (response?.status === 'SUCCESS') {
            this.laboratories = response.response;
            this.paginatedLaboratoriesData = this.getPaginatedLaboratories();
          } else {
            // code to show message box
            this.featureService.notify(response, 'alert');
          }
          this.loading = false;
          let controls;
          setTimeout(() => {
            controls = $('#tabsElement').find('owc-tab');
            $(controls[0]).trigger('click');
            this.ref.markForCheck();
          }, 0);
        },
        (error: any) => {
          console.log('Get laboratories from DB:: error: ' + error);
          this.loading = false;
          this.featureService.notify(
            error?.error ? error.error : error,
            'alert',
          );
        },
      ),
    );
  }

  public pageChangeHandler(event: any): void {
    if (event.detail.pageSize != this.rowsPerPage) {
      event.detail.page = 1;
    }
    this.page = event.detail.page;
    this.rowsPerPage = event.detail.pageSize;
    if (!this.searchTerm) {
      this.paginatedLaboratoriesData = this.getPaginatedLaboratories();
    }
  }

  public inputKeyDownHandler($event: any): void {
    console.log('inputKeyDownHandler', this.searchTerm);
    this.searchSubject.next(this.searchTerm);
  }

  public getPaginatedLaboratories(): any {
    let filteredLabs: any[] = [];
    this.paginatedLaboratoriesData = [];
    let start = (((this.page ?? 1) as number) - 1) * (this.rowsPerPage || 10);
    let end = start + (this.rowsPerPage || 10);

    if (this.allLaboratoriesData.length == 0) {
      let allLabLicenses: any[] = [];
      this.laboratories.forEach((item, index) => {
        if (item.license_entitlements.length > 0) {
          item.license_entitlements.forEach((i: any, idx: any) => {
            i['labId'] = item.labId;
            i['labName'] = item.labName;
            i['countryCode'] = item.countryCode;
            allLabLicenses.push(i);
          });
        }
      });
      this.allLaboratoriesData = allLabLicenses;
    }

    if (this.searchTerm) {
      filteredLabs = this.allLaboratoriesData.filter(
        lab =>
          (lab.labId && lab.labId.includes(this.searchTerm)) ||
          (lab.labName && lab.labName.includes(this.searchTerm)) ||
          (lab.labName &&
            this.searchTerm &&
            lab.labName.toLowerCase().includes(this.searchTerm.toLowerCase())),
      );
      this.page = 1;
      start = (((this.page ?? 1) as number) - 1) * (this.rowsPerPage || 10);
      end = start + (this.rowsPerPage || 10);
    }
    if (filteredLabs.length > 0) {
      this.paginatedLaboratoriesData = filteredLabs;
    } else if (!this.searchTerm) {
      this.paginatedLaboratoriesData = this.allLaboratoriesData;
    }
    this.total = Math.ceil(
      this.paginatedLaboratoriesData.length / (this.rowsPerPage ?? 1),
    );

    return this.paginatedLaboratoriesData.slice(start, end);
  }

  public getFormattedDate(inputDateTime: number): Date {
    let expDate = new Date(inputDateTime * 1000);
    return expDate;
  }

  public edit(lab: any): void {
    const data = lab;
    this.dialog.open(AddModalComponent, { data });
  }

  public clearClickHandler(): void {
    this.searchTerm = '';
    this.searchSubject.next(this.searchTerm);
  }

  public delete(lab: any): void {
    const data = lab;
    this.dialog.open(ConfirmModalComponent, { data });
  }

  public addNewLicense(): void {
    console.log('addNewLicense');
    const data = {};
    this.dialog.open(AddModalComponent, { data });
  }

  public ngOnDestroy(): void {}
}
