
<div class="flex-row col-12" >
  <owc-progress-bar #progressBarElement  [value]="50" *ngIf="loading" style="width: 100%;padding-bottom: 10px;" />
    <div class=" container col-12 mt-24">
      <div [style]="{ width: '2%' }"></div>
          <div [style]="{ width: '78%' }" id="mainLabel"><h3>License management </h3></div>
          <div [style]="{ width: '18%' ,'text-align': '-webkit-right'}">
            <owc-button  variant="primary"   (click)="addNewLicense()" id="new-btn">
              <owc-icon slot="prefix" name="sign_plus" family="outlined" style="margin-right: 8 "></owc-icon>
               Assign new License
            </owc-button>
          </div>
          <div [style]="{ width: '2%' }"></div>
    </div>
    <div class="  container col-12">
        <div [style]="{ width: '2%' }"></div>
        <div [style]="{ width: '96%' }">
            <owc-table #tableElement id="table"  >
              <owc-table-header id="table-header" sticky >
                <owc-table-header-cell width="auto" id="lab-name" width="15%">Lab id</owc-table-header-cell>
                <owc-table-header-cell width="auto" id="lab-name" width="45%">Lab name</owc-table-header-cell>
                <owc-table-header-cell width="auto" id="license-name" width="12%">License name</owc-table-header-cell>
                <owc-table-header-cell width="auto" id="license-name" width="10%">License type</owc-table-header-cell>
                <owc-table-header-cell width="auto" id="expiring-on" width="13%">Expiring on</owc-table-header-cell>
                <owc-table-header-cell width="auto" id="action" width="5%"> </owc-table-header-cell>
              </owc-table-header>
              <owc-table-body id="table-body" >
                <p *ngIf="paginatedLaboratoriesData.length == 0 && searchTerm" style="text-align: center;">No data found</p>
                  
                  <ng-container *ngFor="let lab of paginatedLaboratoriesData; let idx = index" >
                    <owc-table-row >
                      <owc-table-cell id="table-row-labname">{{ lab?.labId }}</owc-table-cell>
                      <owc-table-cell id="table-row-labname">{{ lab?.labName }}</owc-table-cell>
                      <owc-table-cell id="table-row-instrumentLine">{{ lab?.entitlements.entitlement }}</owc-table-cell>
                      <owc-table-cell id="table-row-serialNo">{{ lab?.license_type }}</owc-table-cell>
                      <owc-table-cell id="table-row-serialNo">{{  getFormattedDate(lab?.expiryDate) | date:'dd-MM-YYYY'}}</owc-table-cell>
      
                      <owc-table-cell id="row-action" style="display: block;">
                        <div class="col-12" style="display: flex;justify-content: end;">
                        <div class="col-10" style="display: flex;justify-content: end;">
                          <div   id="action-edit" (click)="edit( lab)" style="margin-right: 5px; word-break: break-word" title="">
                            <owc-icon id="create-ticket-icon" name="write" family="outlined"></owc-icon>
                          </div>
                        </div>
                        <div class="col-2">
                          <div id="action-delete" class="two" (click)="delete( lab)" [style]="{ display: 'grid', 'justify-content': 'end' }">
                            <owc-icon #iconElement id="nevigate-icon" name="delete" type="outlined" [style]="{ cursor: 'pointer' }" ></owc-icon>
                          </div>
                        </div>
                        </div>
                      </owc-table-cell>
      
                    </owc-table-row>
                </ng-container>
              
          
              </owc-table-body>
              <owc-component-header slot="header" >
                Manage licenses
               <div slot="suffix" style="display: flex; align-items: center;">
                  <owc-input
                  #inputElement id="search"
                  [(ngModel)]="searchTerm" 
                  [placeholder]="'Search by lab id or lab name'"
                  (clearClick)="clearClickHandler()"
                  (inputKeyDown)="inputKeyDownHandler($event)"
                >
                <owc-icon slot="prefix" name="search" family="outlined"></owc-icon>
                </owc-input>
                </div>
              </owc-component-header>
        
              <div  id="devices-allDevices-footer" slot="footer">
                <owc-pagination id="devices-allDevices-footer-pagination" [pageSize]="rowsPerPage" [page]="page" 
                [pageSizeOptions]="rowsOptions"  [total]="this.total" (pageChange)="pageChangeHandler($event)" style="float:right">
                    <div id="pagination-rows-count" slot="rows-per-page" > Rows per page </div>
                    <div id="pagination-page-no"> {{page}} <span id="of-txt"> of </span> {{total}} </div>
                </owc-pagination>  
              </div>
            </owc-table>
        </div>
      
        <div [style]="{ width: '2%' }"></div>
    </div> 

</div>