import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appStopAutofocus]',
})
export class StopAutofocusDirective {
  constructor(private element: ElementRef) {}

  @HostListener('focus', ['$event'])
  onFocus(event: FocusEvent): void {
    this.element.nativeElement.blur();
  }
}
