export { b as sanitizeIconToken, s as setGlobalTheme, a as setTheme, t as themeMapping } from './Theming-c3a703e1.js';
export { R as RIPPLE_CONTAINER_CLASSNAME, a as applyRipple } from './Ripple-1f4f7ff7.js';
export { C as ComponentUtils, D as DEFAULT_SLOT_WRAPPER_KEY, a as DefaultSlotWrapper, d as createBEMElement, e as createBEMModifier, b as getAttribute, g as getAttributes, c as getDesignTokenValue, p as parseStyles, r as removeAttributes } from './ComponentUtils-383ff75c.js';
export { e as DRAGGABLE_KEY, f as DRAGGABLE_UNIQUE_DATA_KEY, b as DRAGGING_CLASS, d as DROPZONE_ACTIVE_CLASS, h as DROPZONE_BEHAVIOR_KEY, c as DROPZONE_CLASS, g as DROP_BEHAVIOR_KEY, a as DropBehavior, D as DropzoneBehavior, k as FOCUS, F as FocusType, G as GLOBAL_FOCUS_CLASS, H as HandlerKeyCombination, j as KeyboardCode, K as KeydownManager, M as MutationObserverSafe, R as REMOVE_FOCUS, p as arrangeLimit, n as getRelativePosition, l as handlePreviousFocus, o as inViewport, m as makeDraggable, i as makeDropzone, r as removeDraggable, s as scrollToElement } from './MutationObserverSafe-7a5dacdc.js';
export { _ as _ResizeObserver, o as observeElementSize } from './ResizeObserver-d362a94e.js';
export { i as isChildMutated } from './mutation-record-filters-38a9cac7.js';
export { r as requestTimeout } from './requestTimeout-825f721a.js';
export { a as getResponsiveAttributeValue, g as getScreenSize } from './responsive-0e65b88e.js';
import { r as randomString, g as getDocument, a as getBody, b as generateElementId, s as setElementAttributes } from './element-d46853d9.js';
export { G as GLOBAL_EVENTS, N as NodeType, n as findElementInEventPath, o as findNodeInEventPath, b as generateElementId, a as getBody, c as getComputedStyleSafe, g as getDocument, j as getSiblings, h as hasEllipsis, x as hasValue, t as isDefined, f as isElementInDocument, d as isElementReady, e as isElementVisible, w as isEmptyObject, z as isFloat, B as isNumber, v as isObject, y as isPositiveFloat, A as isPositiveNumber, i as isSSR, u as isString, r as randomString, s as setElementAttributes, k as stopEvent, m as stopImmediatePropagation, l as stopPropagation, p as stringToNumberArray, q as stringToObject } from './element-d46853d9.js';
export { t as taskRunner } from './task-5b004383.js';
export { F as FORMAT_MAP, V as Validator, d as defineFormat, e as endOfDayUTC, g as getTimeInterval, h as handleLocation, i as isTimeEqual, m as moveTimeToToday, a as moveTimeToTodayUTC, s as startOfDayUTC } from './validator.class-04a028fc.js';
export { C as ClickOutside } from './click-outside-b72b8f2a.js';
export { a as Context, C as ContextValue, O as OnContextChange } from './context-6d9d7cb8.js';
export { O as OnElementMutation } from './element-mutation-f8e42e6a.js';
export { O as OnElementResize } from './element-resize-1bd58701.js';
export { L as LongPress } from './long-press-c5d53c33.js';
export { b as accessibility, a as accessibilityAttributes, h as hideElementFromAccessibilityAPI } from './accessibility-720ec3cd.js';
export { u as useRef } from './ref-1da96b54.js';
import { S as SubjectBase } from './subscription-list.class-9c42aac2.js';
export { B as BehaviorSubject, a as SubscriptionList } from './subscription-list.class-9c42aac2.js';
export { a as arrayIncludes, i as isSameArray } from './array-d60b4412.js';
export { n as noop } from './function-22cdd81a.js';
export { D as DATE_SEPARATOR, g as getBrowserDateFormat, a as getDateFnsLocale } from './index-decf52b2.js';
export { a as alpha, d as decomposeColor, h as hexToRgb, r as recomposeColor } from './color-aeab9bc0.js';
export { d as debounce } from './debounce-4740d277.js';
import './index-f6ab9135.js';
import './index-42097b04.js';
import './index-4cf77227.js';
import './index-06d19404.js';
import './index-17b06582.js';
import './index-6dad2db9.js';

const CONSUMER_ID_LENGTH = 32;
class Subject extends SubjectBase {
  next(v) {
    this.consumers.forEach(consumer => consumer === null || consumer === void 0 ? void 0 : consumer(v));
  }
  subscribe(cb) {
    const consumerId = randomString(CONSUMER_ID_LENGTH);
    this.consumers.set(consumerId, cb);
    return {
      unsubscribe: () => this.consumers.delete(consumerId)
    };
  }
}

class GenericComponent {
}

const DEFAULT_SNACKBAR_LIMIT = 3;
const NOTIFICATION_DRAWER_TAG = 'owc-notification-drawer';
/**
 * Snackbar service currently only use for owc-snackbar component.
 * it provides notification action and view with animation
 */
class SnackbarService {
  /** Returns the existing drawer or create a new one */
  getDrawer(limit = DEFAULT_SNACKBAR_LIMIT) {
    var _a, _b, _c;
    let drawer = (_a = getDocument()) === null || _a === void 0 ? void 0 : _a.querySelector(NOTIFICATION_DRAWER_TAG);
    if (!drawer) {
      drawer = (_b = getDocument()) === null || _b === void 0 ? void 0 : _b.createElement(NOTIFICATION_DRAWER_TAG);
      if (drawer) {
        drawer.limit = limit;
        (_c = getBody()) === null || _c === void 0 ? void 0 : _c.appendChild(drawer);
      }
    }
    return drawer;
  }
  /**
   * Updates the snackbar limit.
   * @param limit - limit for items
   */
  setLimit(limit) {
    const drawer = this.getDrawer();
    drawer && (drawer.limit = limit);
  }
  /** Clear all snackbar elements */
  clearAll() {
    var _a;
    (_a = this.getDrawer()) === null || _a === void 0 ? void 0 : _a.querySelectorAll('owc-snackbar').forEach(snackbar => snackbar.remove());
  }
  /**
   * this method add create snackbar and append navigation drawer
   * @param snackbar property of snackbar
   * @returns snackbarService
   */
  show({ message, snackbarProps = {}, actions = [] }) {
    var _a, _b, _c, _d;
    const snackbarElement = (_a = getDocument()) === null || _a === void 0 ? void 0 : _a.createElement('owc-snackbar');
    if (snackbarElement) {
      snackbarElement.id = generateElementId(snackbarElement);
      setElementAttributes(snackbarElement, snackbarProps);
      const contentWrapper = (_b = getDocument()) === null || _b === void 0 ? void 0 : _b.createElement('div');
      if (contentWrapper) {
        contentWrapper.innerHTML = message;
        snackbarElement.appendChild(contentWrapper);
      }
      if (actions.length) {
        const actionsWrapper = (_c = getDocument()) === null || _c === void 0 ? void 0 : _c.createElement('div');
        if (actionsWrapper) {
          actionsWrapper.slot = 'action';
          actionsWrapper.append(...actions.map(({ content, buttonProps = {} }) => {
            var _a;
            const actionButton = (_a = getDocument()) === null || _a === void 0 ? void 0 : _a.createElement('owc-button');
            if (actionButton) {
              actionButton.textContent = content;
              setElementAttributes(actionButton, buttonProps);
            }
            return actionButton;
          }));
          snackbarElement.appendChild(actionsWrapper);
        }
      }
      (_d = this.getDrawer()) === null || _d === void 0 ? void 0 : _d.addChild(snackbarElement);
    }
    return snackbarElement;
  }
}
const snackbarService = new SnackbarService();

export { GenericComponent, Subject, snackbarService };
