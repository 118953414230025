import { proxyCustomElement, HTMLElement, createEvent, h, Host } from '@stencil/core/internal/client';
import { a as getBody, b as generateElementId, i as isSSR, e as isElementVisible } from './element.js';
import { b as accessibility } from './accessibility.js';
import { j as KeyboardCode, F as FocusType, H as HandlerKeyCombination, K as KeydownManager } from './MutationObserverSafe.js';
import { u as useRef } from './ref.js';
import { a as DefaultSlotWrapper, C as ComponentUtils } from './ComponentUtils.js';
import { d as defineCustomElement$3 } from './backdrop.js';
import { d as defineCustomElement$2 } from './card.js';
import { d as defineCustomElement$1 } from './icon.js';

const modalDialogCss = ".owc-modal-dialog{display:none}.owc-modal-dialog owc-card{width:100%}.owc-modal-dialog.owc-modal-dialog--visible{display:-ms-flexbox;display:flex}.owc-modal-dialog.owc-modal-dialog--size-small owc-card{max-width:33.75rem}.owc-modal-dialog.owc-modal-dialog--size-medium owc-card{max-width:45rem}.owc-modal-dialog.owc-modal-dialog--size-large owc-card{max-width:60rem}";

var __decorate = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __rest = (undefined && undefined.__rest) || function (s, e) {
  var t = {};
  for (var p in s)
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
      t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function")
    for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
        t[p[i]] = s[p[i]];
    }
  return t;
};
const QUERY_STRING = 'input:not([tabindex^="-"]):not([disabled]),' +
  'select:not([tabindex^="-"]):not([disabled]),' +
  'textarea:not([tabindex^="-"]):not([disabled]),' +
  'button:not([tabindex^="-"]):not([disabled]),' +
  'a[href]:not([tabindex^="-"]):not([disabled]),' +
  '[tabindex]:not([tabindex^="-"]):not([disabled]),' +
  'area[href], iframe, object, embed, [tabindex="0"], [contenteditable]';
const FOCUS_FILTER_ELEMENTS = ['owc-button', 'owc-icon-button', 'owc-input'];
const DEAFULT_DELAY_FIRST_ELEMENT = 100;
const ModalDialog = /*@__PURE__*/ proxyCustomElement(class ModalDialog extends HTMLElement {
  constructor() {
    super();
    this.__registerHost();
    this.visibleChange = createEvent(this, "visibleChange", 3);
    this.slotWrapperRef = useRef();
    this.cardComponent = useRef();
    this.component = new ComponentUtils(this);
    this.cleanup = () => { var _a; return (_a = getBody()) === null || _a === void 0 ? void 0 : _a.removeEventListener('keydown', this.onKeydown); };
    this.onBackdropClick = (ev, originalHandler) => {
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
      if (!ev.defaultPrevented) {
        !this.disableBackdropClick && (this.visible = false);
      }
    };
    this.onKeydown = (event) => {
      if (this.visible && event.key === KeyboardCode.Escape) {
        !this.disableEscapeKeydown && this.visible && (this.visible = false);
      }
    };
    this.onFocusHandler = () => {
      this.component.keydownManager().setCurrentItem(this.getFirstFocusableItem());
    };
    this.getFirstFocusableItem = () => {
      const selectableElements = this.getFocusable();
      const selectableFirstElement = selectableElements === null || selectableElements === void 0 ? void 0 : selectableElements.find(el => el.hasAttribute('autofocus'));
      return selectableFirstElement || selectableElements[0];
    };
    this.visible = false;
    this.disableBackdropClick = false;
    this.disableEscapeKeydown = false;
    this.disableScrollLock = false;
    this.hideBackdrop = false;
    this.size = 'medium';
    this.cardProps = undefined;
    this.backdropProps = undefined;
    this.disableKeydownManager = false;
    this.noAutoFocus = false;
    this.cardID = generateElementId('card');
  }
  handleVisibleChanges(newValue, oldValue) {
    if (!!oldValue !== !!newValue) {
      this.visibleChange.emit(newValue);
      if (this.visible) {
        this.noAutoFocus
          ? setTimeout(() => this.component.keydownManager().setCurrentItem(this.cardComponent.current))
          : setTimeout(() => this.onFocusHandler(), DEAFULT_DELAY_FIRST_ELEMENT);
      }
    }
  }
  onDisableKeydownManagerChanged(newValue, oldValue) {
    if (!!oldValue !== !!newValue) {
      newValue ? this.component.keydownManager().detachEventListeners() : this.component.keydownManager().attachEventListeners();
    }
  }
  componentDidLoad() {
    this.onDisableKeydownManagerChanged(this.disableKeydownManager, false);
    this.visible && !this.noAutoFocus && setTimeout(() => this.onFocusHandler(), DEAFULT_DELAY_FIRST_ELEMENT);
  }
  connectedCallback() {
    var _a;
    this.initKeydownManager();
    this.cleanup();
    (_a = getBody()) === null || _a === void 0 ? void 0 : _a.addEventListener('keydown', this.onKeydown);
  }
  disconnectedCallback() {
    this.cleanup();
  }
  initKeydownManager() {
    this.component.withKeydownManager({
      circular: true,
      focusType: FocusType.BY_EVENT,
      parentSelector: () => this.cardComponent.current,
      selector: () => this.getFocusable(),
      onHostFocus: () => this.onFocusHandler(),
      handlers: [
        {
          key: [HandlerKeyCombination.Tab],
          handler: KeydownManager.PredefinedHandler.NEXT
        },
        {
          key: [HandlerKeyCombination.ShiftTab],
          handler: KeydownManager.PredefinedHandler.PREVIOUS
        }
      ]
    });
  }
  getFocusable() {
    var _a;
    const focusableItems = Array.from(((_a = this.cardComponent.current) === null || _a === void 0 ? void 0 : _a.querySelectorAll(QUERY_STRING)) || []);
    if (focusableItems.length === 0) {
      return [this.hostElement];
    }
    return focusableItems
      .filter(el => { var _a; return !isSSR() && isElementVisible(el) && !FOCUS_FILTER_ELEMENTS.includes((_a = el === null || el === void 0 ? void 0 : el.tagName) === null || _a === void 0 ? void 0 : _a.toLowerCase()); })
      .map(el => el);
  }
  render() {
    const hostClasses = this.component.getHostBemClasses({ [`size-${this.size || 'medium'}`]: true, visible: this.visible });
    const _a = this.backdropProps || {}, { onBackdropClick: originalBackdropClick } = _a, backdropProps = __rest(_a, ["onBackdropClick"]);
    const _b = this.cardProps || {}, { ref: cardRef } = _b, cardProps = __rest(_b, ["ref"]);
    return (h(Host, Object.assign({ class: hostClasses, tabIndex: !this.visible ? -1 : this.component.getInitialTabIndex() }, accessibility({ role: 'dialog', aria: { labelledby: this.cardID } })), h("owc-backdrop", Object.assign({ hidden: !this.visible, opacity: this.hideBackdrop ? 0 : undefined, disableScrollLock: this.disableScrollLock }, backdropProps, { onBackdropClick: e => this.onBackdropClick(e, originalBackdropClick) }), h("owc-card", Object.assign({}, cardProps, { id: this.cardID, ref: el => this.cardComponent(el, [cardRef]) }), h("slot", { name: "header" }), h("div", { ref: this.slotWrapperRef }, h("slot", null)), h("slot", { name: "footer" })))));
  }
  get hostElement() { return this; }
  static get watchers() { return {
    "visible": ["handleVisibleChanges"],
    "disableKeydownManager": ["onDisableKeydownManagerChanged"]
  }; }
  static get style() { return modalDialogCss; }
}, [4, "owc-modal-dialog", {
    "visible": [1540],
    "disableBackdropClick": [516, "disable-backdrop-click"],
    "disableEscapeKeydown": [516, "disable-escape-keydown"],
    "disableScrollLock": [516, "disable-scroll-lock"],
    "hideBackdrop": [516, "hide-backdrop"],
    "size": [513],
    "cardProps": [16],
    "backdropProps": [16],
    "disableKeydownManager": [4, "disable-keydown-manager"],
    "noAutoFocus": [516, "no-auto-focus"],
    "cardID": [32]
  }]);
__decorate([
  DefaultSlotWrapper()
], ModalDialog.prototype, "slotWrapperRef", void 0);
function defineCustomElement() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-modal-dialog", "owc-backdrop", "owc-card", "owc-icon"];
  components.forEach(tagName => { switch (tagName) {
    case "owc-modal-dialog":
      if (!customElements.get(tagName)) {
        customElements.define(tagName, ModalDialog);
      }
      break;
    case "owc-backdrop":
      if (!customElements.get(tagName)) {
        defineCustomElement$3();
      }
      break;
    case "owc-card":
      if (!customElements.get(tagName)) {
        defineCustomElement$2();
      }
      break;
    case "owc-icon":
      if (!customElements.get(tagName)) {
        defineCustomElement$1();
      }
      break;
  } });
}

export { ModalDialog as M, defineCustomElement as d };
