import { proxyCustomElement, HTMLElement, createEvent, h, Host } from '@stencil/core/internal/client';
import { B as isNumber, b as generateElementId, a as getBody, i as isSSR, e as isElementVisible } from './element.js';
import { b as accessibility } from './accessibility.js';
import { j as KeyboardCode, F as FocusType, H as HandlerKeyCombination, K as KeydownManager } from './MutationObserverSafe.js';
import { u as useRef } from './ref.js';
import { a as DefaultSlotWrapper, C as ComponentUtils } from './ComponentUtils.js';
import { d as defineCustomElement$6 } from './backdrop.js';
import { d as defineCustomElement$5 } from './card.js';
import { d as defineCustomElement$4 } from './component-header.js';
import { d as defineCustomElement$3 } from './icon.js';
import { d as defineCustomElement$2 } from './icon-button.js';

const workflowOverlayCss = ".owc-workflow-overlay{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;position:fixed;top:0;left:0;right:0;bottom:0;z-index:-1;visibility:hidden;-webkit-transition:-webkit-transform 1s;transition:-webkit-transform 1s;transition:transform 1s;transition:transform 1s, -webkit-transform 1s;-webkit-transform:translateY(100%);transform:translateY(100%)}.owc-workflow-overlay>.owc-workflow-overlay__backdrop-element{padding:0;-webkit-transition:none;transition:none;overflow:visible}.owc-workflow-overlay>.owc-workflow-overlay__backdrop-element .owc-workflow-overlay__backdrop-element__overlay-element .owc-workflow-overlay__header-wrapper [slot=prefix]{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;-ms-flex-pack:start;justify-content:flex-start;gap:0.25rem}.owc-workflow-overlay.owc-workflow-overlay--visible{z-index:var(--one-z-index-workflow-overlay);visibility:visible;-webkit-transform:translateY(0);transform:translateY(0)}.owc-workflow-overlay [slot=content]{padding:2rem 1.75rem}.owc-workflow-overlay [slot=footer]{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;-ms-flex-pack:end;justify-content:flex-end}.owc-workflow-overlay [slot=footer]:not(:empty){padding:1rem}.owc-workflow-overlay .owc-workflow-overlay__slot-wrapper{height:100%}";

var __decorate = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __rest = (undefined && undefined.__rest) || function (s, e) {
  var t = {};
  for (var p in s)
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
      t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function")
    for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
        t[p[i]] = s[p[i]];
    }
  return t;
};
const QUERY_STRING = 'input:not([tabindex^="-"]):not([disabled]),' +
  'select:not([tabindex^="-"]):not([disabled]),' +
  'textarea:not([tabindex^="-"]):not([disabled]),' +
  'button:not([tabindex^="-"]):not([disabled]),' +
  'a[href]:not([tabindex^="-"]):not([disabled]),' +
  '[tabindex]:not([tabindex^="-"]):not([disabled]),' +
  'area[href], iframe, object, embed, [tabindex="0"], [contenteditable]';
const FOCUS_FILTER_ELEMENTS = ['owc-button', 'owc-icon-button', 'owc-input'];
const WorkflowOverlay = /*@__PURE__*/ proxyCustomElement(class WorkflowOverlay extends HTMLElement {
  constructor() {
    super();
    this.__registerHost();
    this.visibleChange = createEvent(this, "visibleChange", 3);
    this.contentWrapperRef = useRef();
    this.cardComponent = useRef();
    this.component = new ComponentUtils(this);
    this.setVisible = (newValue) => {
      if (!!this.visible !== !!newValue) {
        if (this.controlled) {
          this.visibleChange.emit(newValue);
        }
        else {
          this.visible = !!newValue;
        }
      }
    };
    this.onKeydown = (event) => {
      if (this.visible && (event.key === KeyboardCode.Escape || event.key === KeyboardCode.EscapeAlt)) {
        !this.disableEscapeKeydown && this.setVisible(false);
      }
    };
    this.getWidth = () => (isNumber(this.width) ? `${this.width}%` : this.width);
    this.getHeight = () => (isNumber(this.height) ? `${this.height}%` : this.height);
    this.onBackdropClick = (ev, originalHandler) => {
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
      if (!ev.defaultPrevented) {
        !this.disableBackdropClick && this.setVisible(false);
      }
    };
    this.onCloseIconClick = (ev, originalHandler) => {
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
      if (!ev.defaultPrevented) {
        this.setVisible(false);
      }
    };
    this.width = '100%';
    this.height = '100%';
    this.disableBackdropClick = false;
    this.disableEscapeKeydown = false;
    this.hideBackdrop = false;
    this.controlled = false;
    this.visible = false;
    this.noCloseIcon = false;
    this.cardProps = undefined;
    this.backdropProps = undefined;
    this.closeIconProps = undefined;
    this.cardID = generateElementId('owc-card');
  }
  handleVisibility(newValue, oldValue) {
    if (!!oldValue !== !!newValue) {
      !this.controlled && this.visibleChange.emit(newValue);
      this.visible && setTimeout(() => this.component.keydownManager().setCurrentItem(this.cardComponent.current));
    }
  }
  connectedCallback() {
    var _a;
    this.initKeydownManager();
    this.cleanup();
    (_a = getBody()) === null || _a === void 0 ? void 0 : _a.addEventListener('keydown', this.onKeydown);
  }
  disconnectedCallback() {
    this.cleanup();
  }
  cleanup() {
    var _a;
    (_a = getBody()) === null || _a === void 0 ? void 0 : _a.removeEventListener('keydown', this.onKeydown);
  }
  initKeydownManager() {
    this.component.withKeydownManager({
      circular: true,
      focusType: FocusType.BY_EVENT,
      parentSelector: () => this.cardComponent.current,
      selector: () => this.getFocusable(),
      handlers: [
        {
          key: [HandlerKeyCombination.Tab],
          handler: KeydownManager.PredefinedHandler.NEXT
        },
        {
          key: [HandlerKeyCombination.ShiftTab],
          handler: KeydownManager.PredefinedHandler.PREVIOUS
        }
      ]
    });
  }
  getFocusable() {
    var _a;
    const focusableItems = Array.from(((_a = this.cardComponent.current) === null || _a === void 0 ? void 0 : _a.querySelectorAll(QUERY_STRING)) || []);
    if (focusableItems.length === 0) {
      return [this.hostElement];
    }
    return focusableItems
      .filter(el => { var _a; return !isSSR() && isElementVisible(el) && !FOCUS_FILTER_ELEMENTS.includes((_a = el === null || el === void 0 ? void 0 : el.tagName) === null || _a === void 0 ? void 0 : _a.toLowerCase()); })
      .map(el => el);
  }
  render() {
    const hostClasses = this.component.getHostBemClasses({ visible: this.visible });
    const _a = this.backdropProps || {}, { onBackdropClick: originalBackdropClick, className: backdropOriginalClassName } = _a, backdropProps = __rest(_a, ["onBackdropClick", "className"]);
    const _b = this.cardProps || {}, { ref: cardRef, className: cardOriginalClassName } = _b, cardProps = __rest(_b, ["ref", "className"]);
    const _c = this.closeIconProps || {}, { onclick: originalCloseIconClick } = _c, closeIconProps = __rest(_c, ["onclick"]);
    const backdropElementClasses = this.component.getChildBemClasses('backdrop-element', hostClasses, {}, backdropOriginalClassName);
    const overlayElementClasses = this.component.getChildBemClasses('overlay-element', backdropElementClasses, {}, cardOriginalClassName);
    const headerWrapper = this.component.getChildBemClasses('header-wrapper', hostClasses);
    const slotWrapperClasses = this.component.getChildBemClasses('slot-wrapper', hostClasses);
    return (h(Host, Object.assign({ class: hostClasses }, accessibility({ role: 'dialog', aria: { labelledby: this.cardID } })), h("owc-backdrop", Object.assign({ class: backdropElementClasses, hidden: !this.visible, opacity: this.hideBackdrop ? 0 : undefined }, backdropProps, { onBackdropClick: e => this.onBackdropClick(e, originalBackdropClick) }), h("owc-card", Object.assign({ class: overlayElementClasses, id: this.cardID, style: { width: this.getWidth(), height: this.getHeight() } }, cardProps, { ref: el => this.cardComponent(el, [cardRef]) }), h("owc-component-header", { slot: "header", class: headerWrapper }, h("div", { slot: "prefix" }, !this.noCloseIcon ? (h("owc-icon-button", Object.assign({ family: "outlined", icon: "clear" }, closeIconProps, { onClick: e => this.onCloseIconClick(e, originalCloseIconClick) }))) : null, h("slot", { name: "header" }))), h("div", { class: slotWrapperClasses, ref: this.contentWrapperRef }, h("slot", { name: "content" })), h("slot", { name: "footer" })))));
  }
  get hostElement() { return this; }
  static get watchers() { return {
    "visible": ["handleVisibility"]
  }; }
  static get style() { return workflowOverlayCss; }
}, [4, "owc-workflow-overlay", {
    "width": [513],
    "height": [513],
    "disableBackdropClick": [516, "disable-backdrop-click"],
    "disableEscapeKeydown": [516, "disable-escape-keydown"],
    "hideBackdrop": [516, "hide-backdrop"],
    "controlled": [516],
    "visible": [1540],
    "noCloseIcon": [516, "no-close-icon"],
    "cardProps": [16],
    "backdropProps": [16],
    "closeIconProps": [16],
    "cardID": [32]
  }]);
__decorate([
  DefaultSlotWrapper()
], WorkflowOverlay.prototype, "contentWrapperRef", void 0);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-workflow-overlay", "owc-backdrop", "owc-card", "owc-component-header", "owc-icon", "owc-icon-button"];
  components.forEach(tagName => { switch (tagName) {
    case "owc-workflow-overlay":
      if (!customElements.get(tagName)) {
        customElements.define(tagName, WorkflowOverlay);
      }
      break;
    case "owc-backdrop":
      if (!customElements.get(tagName)) {
        defineCustomElement$6();
      }
      break;
    case "owc-card":
      if (!customElements.get(tagName)) {
        defineCustomElement$5();
      }
      break;
    case "owc-component-header":
      if (!customElements.get(tagName)) {
        defineCustomElement$4();
      }
      break;
    case "owc-icon":
      if (!customElements.get(tagName)) {
        defineCustomElement$3();
      }
      break;
    case "owc-icon-button":
      if (!customElements.get(tagName)) {
        defineCustomElement$2();
      }
      break;
  } });
}

const OwcWorkflowOverlay = WorkflowOverlay;
const defineCustomElement = defineCustomElement$1;

export { OwcWorkflowOverlay, defineCustomElement };
