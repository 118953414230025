import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { View } from './shared/models/view.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  public view = View;
  public view$: Observable<View> = this.breakpointObserver
    .observe('(min-width: 1200px)')
    .pipe(
      map(result => result.matches),
      map((desktop: boolean) => {
        if (!desktop) {
          return View.Mobile;
        } else {
          return View.DesktopLoggedIn;
        }
      }),
    );

  public constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
  ) {}

  public ngOnInit(): void {
    this.router.events.subscribe(() => window.scrollTo(0, 0));
  }
}
