import {
  Component,
  ViewChild,
  Renderer2,
  Inject,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Components } from '@one/web-components';
import { OnInit } from '@angular/core';
import { FeatureService } from '../../../../services/feature.service'; // Import the FeatureService class
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-add-modal',
  templateUrl: './add-modal.component.html',
  styleUrl: './add-modal.component.scss',
})
export class AddModalComponent implements OnInit, AfterViewInit {
  @ViewChild('modalDialogElement', { static: true })
  modalDialogElement!: Components.OwcModalDialog;
  @ViewChild('editableDropdownElement', { static: true })
  editableDropdownElement!: Components.OwcEditableDropdown;
  @ViewChild('progressBarElement', { static: true })
  progressBarElement!: Components.OwcProgressBar;
  @ViewChild('datepickerElement', { static: true })
  public datepickerElement!: Components.OwcDatepicker;

  @ViewChild('editableDropdownElement1') inputElement: ElementRef | undefined;

  public subscription: Subscription[] = [];

  loading = false;
  visible: boolean = false;
  selectedCountry: string = '';
  selectedLaboratory: string = '';
  selectedLicense: string = '';
  licenseType: string = '';
  direction: string = 'horizontal';
  countries: any[] | undefined;
  laboratories: any[] | undefined;
  licenseList: any[] | undefined;
  datePickerPlaceholder = 'Please select valid date(s)';
  expiryDate: Date = new Date();
  today: Date | undefined;
  datePickerType: string = 'single';
  selectedLabName: string = '';
  trialLicenseDays: number = 60;
  todayInMilliSeconds: number = 0;
  delayCountry: boolean = true;

  constructor(
    public datepipe: DatePipe,
    private renderer: Renderer2,
    public dialog: MatDialog,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private featureService: FeatureService,
  ) {
    this.visible = true;
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.delayCountry = false;
    }, 1000);
  }

  public ngOnInit(): void {
    this.todayInMilliSeconds = new Date().getTime();
    this.loading = false;
    this.today = new Date();
    this.datePickerType = 'single';
    this.countries = [];
    this.licenseType = 'trial';
    console.log('today: ' + this.today);
    this.laboratories = [];
    this.licenseList = ['Firewall', 'Vulnerabilities', 'Advisories', 'SoC'];
    this.getCountries();
    if (this.data?.countryCode && this.data?.labId) {
      this.selectedCountry = this.data.countryCode;
      this.laboratories = [
        {
          name: this.data.labName,
          id: this.data.labId,
        },
      ];
      this.selectedLicense = this.data?.entitlements?.entitlement;
      this.licenseType = this.data?.license_type;
      this.expiryDate = new Date(this.data?.expiryDate * 1000);
      setTimeout(() => {
        this.selectedLaboratory = this.data.labId;
      }, 500);
    } else {
      this.expiryDate.setDate(this.today.getDate() + this.trialLicenseDays);
    }
  }

  public getCountries(): void {
    this.countries = [
      { name: 'Argentina', value: 'AR' },
      { name: 'Australia', value: 'AU' },
      { name: 'Austria', value: 'AT' },
      { name: 'Bahrain', value: 'UAE' },
      { name: 'Belgium', value: 'BE' },
      { name: 'Botswana', value: 'ZA' },
      { name: 'Brazil', value: 'BR' },
      { name: 'Burundi', value: 'ZA' },
      { name: 'Canada', value: 'CA' },
      { name: 'Chile', value: 'CL' },
      { name: 'China', value: 'CN' },
      { name: 'Colombia', value: 'CO' },
      { name: 'Croatia', value: 'HR' },
      { name: 'Czech Republic', value: 'CZ' },
      { name: 'Denmark', value: 'DK' },
      { name: 'Denmark', value: 'DK' },
      { name: 'Ecuador', value: 'EC' },
      { name: 'Egypt', value: 'EG' },
      { name: 'Eritrea', value: 'ZA' },
      { name: 'Ethiopia', value: 'ZA' },
      { name: 'Finland', value: 'FI' },
      { name: 'France', value: 'FR' },
      { name: 'Germany', value: 'DE' },
      { name: 'Ghana', value: 'ZA' },
      { name: 'Greece', value: 'GR' },
      { name: 'Guatemala', value: 'GT' },
      { name: 'Hong Kong', value: 'HK' },
      { name: 'Hungary', value: 'HU' },
      { name: 'India', value: 'IN' },
      { name: 'Indonesia', value: 'ID' },
      { name: 'Iran', value: 'IR' },
      { name: 'Iraq', value: 'IQ' },
      { name: 'Israel', value: 'IL' },
      { name: 'UK & Ireland', value: 'UK' },
      { name: 'Italy', value: 'IT' },
      { name: 'Japan', value: 'JP' },
      { name: 'Jordan', value: 'UAE' },
      { name: 'Kenya', value: 'KE' },
      { name: 'South Korea', value: 'KR' },
      { name: 'Kuwait', value: 'KW' },
      { name: 'Latvia', value: 'LV' },
      { name: 'Lebanon', value: 'UAE' },
      { name: 'Lesotho', value: 'ZA' },
      { name: 'Libya', value: 'UAE' },
      { name: 'Lithuania', value: 'LT' },
      { name: 'Malawi', value: 'ZA' },
      { name: 'Maldives', value: 'UAE' },
      { name: 'Malaysia', value: 'MY' },
      { name: 'Mauritius', value: 'ZA' },
      { name: 'Mexico', value: 'MX' },
      { name: 'Mozambique', value: 'ZA' },
      { name: 'Namibia', value: 'ZA' },
      { name: 'Netherlands', value: 'NL' },
      { name: 'New Zealand', value: 'NZ' },
      { name: 'South Africa', value: 'ZA' },
      { name: 'Norway', value: 'NO' },
      { name: 'Oman', value: 'UAE' },
      { name: 'Pakistan', value: 'PK' },
      { name: 'Palestine', value: 'UAE' },
      { name: 'Panama', value: 'PA' },
      { name: 'Peru', value: 'PE' },
      { name: 'Philippines', value: 'PH' },
      { name: 'Poland', value: 'PL' },
      { name: 'Portugal', value: 'PT' },
      { name: 'Qatar', value: 'UAE' },
      { name: 'Romania', value: 'RO' },
      { name: 'Russia', value: 'RU' },
      { name: 'Rwanda', value: 'ZA' },
      { name: 'Saudi Arabia', value: 'SA' },
      { name: 'Singapore', value: 'SG' },
      { name: 'Slovakia', value: 'SK' },
      { name: 'Slovenia', value: 'SI' },
      { name: 'Somalia', value: 'ZA' },
      { name: 'South Africa', value: 'ZA' },
      { name: 'Spain', value: 'ES' },
      { name: 'Sudan', value: 'ZA' },
      { name: 'Swaziland', value: 'ZA' },
      { name: 'Sweden', value: 'SE' },
      { name: 'Switzerland', value: 'CH' },
      { name: 'Syria', value: 'UAE' },
      { name: 'Taiwan', value: 'TW' },
      { name: 'Tanzania', value: 'ZA' },
      { name: 'Thailand', value: 'TH' },
      { name: 'Turkey', value: 'TR' },
      { name: 'UAE', value: 'UAE' },
      { name: 'Uganda', value: 'ZA' },
      { name: 'Uruguay', value: 'UY' },
      { name: 'USA', value: 'US' },
      { name: 'USA', value: 'US' },
      { name: 'Vietnam', value: 'VN' },
      { name: 'Yemen', value: 'UAE' },
      { name: 'Zambia', value: 'ZA' },
      { name: 'Zimbabwe', value: 'ZA' },
      { name: 'Morocco', value: 'MA' },
      { name: 'Paraguay', value: 'PY' },
      { name: 'Dominican Republic', value: 'DO' },
      { name: 'Costa Rica', value: 'CR' },
      { name: 'El Salvador', value: 'SV' },
      { name: 'Honduras', value: 'HN' },
      { name: 'Nicaragua', value: 'NI' },
      { name: 'Cuba', value: 'CU' },
      { name: 'Trinidad and Tobago', value: 'TT' },
      { name: 'Barbados', value: 'BB' },
      { name: 'Jamaica', value: 'JM' },
      { name: 'Nigeria', value: 'NG' },
    ];
  }

  public onCountryChange(event: any): void {
    // this.selectedCountry = event.value;
    console.log('selcted country:  ' + this.selectedCountry);
    this.selectedLaboratory = '';
    this.laboratories = [];
    this.loading = true;
    setTimeout(() => {
      this.getLaboratoriesWithLicenses();
    }, 1000);
  }

  public onLaboratoryChange(event: any): void {
    if (this.laboratories && this.laboratories.length > 0) {
      const selectedLab = this.laboratories.find(
        lab => lab.id === this.selectedLaboratory,
      );
      this.selectedLabName = selectedLab ? selectedLab.name : '';

      console.log('selcted onLaboratoryChange:  ' + this.selectedLabName);
    }
    console.log('selcted onLaboratoryChange:  ' + this.selectedLaboratory);
  }
  public onLicenseChange(event: any): void {}

  public onLicenseTypeChange(licenseType: any): void {
    console.log('selcted onLicenseChange:  ' + licenseType);
    this.licenseType = licenseType;
    const today = new Date();

    if (this.licenseType === 'paid' && !this.data?.countryCode) {
      this.expiryDate = new Date();
      this.expiryDate.setDate(today.getDate() + 364);
    } else if (this.licenseType === 'trial' && !this.data?.countryCode) {
      this.expiryDate = new Date();
      this.expiryDate.setDate(today.getDate() + this.trialLicenseDays);
    }
  }

  public getLaboratoriesWithLicenses(): void {
    let url = '';
    if (!this.selectedCountry) {
      this.loading = false;
      return;
    }

    url = `${this.featureService.licenseUrl}/laboratories/all/${this.selectedCountry}/`;
    this.subscription.push(
      this.featureService.get(url).subscribe(
        (response: any) => {
          if (response?.status === 'SUCCESS') {
            this.laboratories = response?.response?.records;
            //console.log('laboratories :: ', this.laboratories);
          } else {
            // code to show message box
            this.featureService.notify(response, 'alert');
          }
          this.loading = false;
        },
        (error: any) => {
          this.loading = false;
          console.log(
            'Get all laboratories from Rexis for given country error: ' + error,
          );
          this.featureService.notify(
            error?.error ? error.error : error,
            'alert',
          );
        },
      ),
    );
  }

  // public onDatePickerPanelOpen(event: any): void {
  //   const quickSelectionsElements =
  //     document.querySelectorAll('.quick-selections');
  //   quickSelectionsElements.forEach(element => {
  //     this.renderer.setStyle(element, 'display', 'none');
  //   });
  // }

  public onDateValueChange(event?: any): void {
    let dt;
    if (event?.detail) {
      dt = event.detail;
    } else if (event) {
      dt = event;
    }
    this.expiryDate = new Date(dt);
    console.log('onDateValueChange: ', this.expiryDate);
  }

  public addEditLicense(): void {
    //this.visible = true;
    const dt = new Date(this.expiryDate);
    let expDate = this.datepipe.transform(dt, 'dd-MM-yyyy');
    if (dt.getTime() < this.todayInMilliSeconds) {
      const errorObj = {
        code: '500',
        response: {},
        status: 'SUCCESS',
        message: "Expiry date should be greater than today's date",
      };
      this.featureService.notify(errorObj, 'alert');
      return;
    }

    let url = `${this.featureService.licenseUrl}/laboratories/`;
    let params = {};
    if (this.data.countryCode && this.data.labId) {
      // Edit
      url = url + `${this.data.countryCode}/${this.data.labId}/`;
      params = {
        lab_name: this.data.labName,
        [this.selectedLicense]: {
          id: this.data.license_entitlement_id,
          enabled: true,
          expiry_date: expDate,
          license_type: this.licenseType,
        },
      };
    } else {
      // ADD License
      params = {
        lab_name: this.selectedLabName,
        [this.selectedLicense]: {
          enabled: true,
          expiry_date: expDate,
          license_type: this.licenseType,
        },
      };
      url = url + `${this.selectedCountry}/${this.selectedLaboratory}/`;
    }

    this.subscription.push(
      this.featureService.post(url, params).subscribe(
        (response: any) => {
          if (response?.status === 'SUCCESS') {
            console.log('response :: ', response);
            this.featureService.notify(response, 'positive');
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            // code to show message box
            this.featureService.notify(response, 'alert');
          }
        },
        (error: any) => {
          console.log('AdminApp: Add Edit License error: ' + error);
          this.featureService.notify(
            error?.error ? error.error : error,
            'alert',
          );
        },
      ),
    );
  }

  public closePopup(): void {
    this.dialog.closeAll();
    this.visible = false;
  }
}
