import { proxyCustomElement, HTMLElement, createEvent, h, Host } from '@stencil/core/internal/client';
import { n as findElementInEventPath, m as stopImmediatePropagation, k as stopEvent } from './element.js';
import { b as accessibility } from './accessibility.js';
import { F as FocusType, K as KeydownManager, H as HandlerKeyCombination } from './MutationObserverSafe.js';
import { C as ComponentUtils } from './ComponentUtils.js';

const dotsCss = ".owc-dots{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;outline:none;padding:0.125rem}.owc-dots.owc-dots--disabled{pointer-events:none}";

const DOT_ITEM_SELECTOR = 'owc-dot';
const Dots = /*@__PURE__*/ proxyCustomElement(class Dots extends HTMLElement {
  constructor() {
    super();
    this.__registerHost();
    this.selectedChange = createEvent(this, "selectedChange", 3);
    this.component = new ComponentUtils(this);
    this.setSelected = (newValue) => {
      if (newValue !== this.selected) {
        if (this.controlled) {
          this.selectedChange.emit(newValue);
        }
        else {
          this.selected = newValue;
        }
      }
    };
    this.onSelectHandler = () => {
      const currentItem = this.component.keydownManager().getCurrentItem();
      this.setSelected(this.getChildElements().findIndex(el => currentItem.isSameNode(el)));
    };
    this.getSelectableDotElements = () => this.getChildElements().filter(item => !item.disabled);
    this.getChildElements = () => Array.from(this.hostElement.querySelectorAll(DOT_ITEM_SELECTOR));
    this.disabled = false;
    this.controlled = false;
    this.selected = undefined;
  }
  onSelectedChanged(newValue, oldValue) {
    newValue !== oldValue && !this.controlled && this.selectedChange.emit(newValue);
    this.propagateAttributesToChildren();
  }
  onDisabledChanged(newValue, oldValue) {
    !oldValue && newValue && this.propagateAttributesToChildren();
  }
  onSelectedChange(event) {
    const item = findElementInEventPath(event, DOT_ITEM_SELECTOR);
    if (item) {
      const index = this.getChildElements().findIndex(el => item.isSameNode(el));
      stopImmediatePropagation(event);
      stopEvent(event);
      event.detail && this.setSelected(index);
      this.propagateAttributesToChildren();
    }
  }
  onDotItemClick(event) {
    const item = findElementInEventPath(event, DOT_ITEM_SELECTOR);
    if (item && !item.disabled) {
      stopEvent(event);
      const index = this.getChildElements().findIndex(el => item.isSameNode(el));
      this.setSelected(index);
      this.hostElement.focus();
      this.component.keydownManager().setCurrentItem(item, 'top', false);
    }
  }
  propagateAttributesToChildren() {
    this.getChildElements().forEach((el, index) => {
      el.tabIndex = -1;
      el.selected = index === this.selected;
      el.controlled = this.controlled;
      el.parentDisabled = this.disabled;
    });
  }
  componentWillRender() {
    this.propagateAttributesToChildren();
  }
  componentDidLoad() {
    this.propagateAttributesToChildren();
  }
  connectedCallback() {
    this.initKeydownManager();
  }
  initKeydownManager() {
    this.component
      .withKeydownManager({
      circular: true,
      focusType: FocusType.BY_CLASS,
      selector: () => this.getSelectableDotElements(),
      parentSelector: () => this.hostElement,
      onHostFocus: (...args) => {
        if (this.selected) {
          this.component.keydownManager().setCurrentIndex(this.selected);
        }
        else {
          this.component.keydownManager().DefaultHandler[KeydownManager.PredefinedHandler.FIRST](...args);
        }
      },
      onHostBlur: () => this.component.keydownManager().clearFocusClassFromAllItems(),
      handlers: [
        {
          key: [HandlerKeyCombination.ArrowDown, HandlerKeyCombination.ArrowRight],
          handler: KeydownManager.PredefinedHandler.NEXT,
          scrollBehavior: 'left'
        },
        {
          key: [HandlerKeyCombination.ArrowUp, HandlerKeyCombination.ArrowLeft],
          handler: KeydownManager.PredefinedHandler.PREVIOUS,
          scrollBehavior: 'right'
        },
        { key: [HandlerKeyCombination.Space], handler: () => this.onSelectHandler() },
        { key: HandlerKeyCombination.ShiftTab, handler: KeydownManager.PredefinedHandler.HOST_BLUR, preventDefault: false }
      ]
    })
      .keydownManager()
      .clearFocusClassFromAllItems();
  }
  render() {
    const hostClasses = this.component.getHostBemClasses({ disabled: this.disabled });
    return (h(Host, Object.assign({ class: hostClasses, tabIndex: this.disabled ? -1 : this.component.getInitialTabIndex(0) }, accessibility({ role: 'list' })), h("slot", null)));
  }
  get hostElement() { return this; }
  static get watchers() { return {
    "selected": ["onSelectedChanged"],
    "disabled": ["onDisabledChanged"]
  }; }
  static get style() { return dotsCss; }
}, [4, "owc-dots", {
    "disabled": [516],
    "controlled": [516],
    "selected": [1538]
  }, [[0, "selectedChange", "onSelectedChange"], [0, "click", "onDotItemClick"]]]);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-dots"];
  components.forEach(tagName => { switch (tagName) {
    case "owc-dots":
      if (!customElements.get(tagName)) {
        customElements.define(tagName, Dots);
      }
      break;
  } });
}

const OwcDots = Dots;
const defineCustomElement = defineCustomElement$1;

export { OwcDots, defineCustomElement };
