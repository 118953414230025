import { proxyCustomElement, HTMLElement, createEvent, h, Host } from '@stencil/core/internal/client';
import { i as isSameArray } from './array.js';
import { x as hasValue, k as stopEvent, n as findElementInEventPath, p as stringToNumberArray } from './element.js';
import { V as Validator, b as moveTimeToTodayUTC, l as set, i as isTimeEqual, m as moveTimeToToday, F as FORMAT_MAP, n as addMinutes, h as handleLocation, f as isMatch, o as subMinutes, p as parse, s as startOfDayUTC, e as endOfDayUTC, c as getTimeInterval, d as defineFormat } from './long-press.js';
import { a as accessibilityAttributes, b as accessibility } from './accessibility.js';
import { j as KeyboardCode } from './MutationObserverSafe.js';
import { u as useRef } from './ref.js';
import { a as DefaultSlotWrapper, C as ComponentUtils } from './ComponentUtils.js';
import { d as defineCustomElement$d } from './card.js';
import { d as defineCustomElement$c } from './checkbox.js';
import { d as defineCustomElement$b } from './divider.js';
import { d as defineCustomElement$a } from './icon.js';
import { d as defineCustomElement$9 } from './icon-button.js';
import { d as defineCustomElement$8 } from './input.js';
import { d as defineCustomElement$7 } from './list.js';
import { d as defineCustomElement$6 } from './list-item.js';
import { d as defineCustomElement$5 } from './menu.js';
import { d as defineCustomElement$4 } from './popover.js';
import { d as defineCustomElement$3 } from './radio.js';
import { d as defineCustomElement$2 } from './tooltip.js';
import { i as isValid, l as locale } from './index2.js';
import { f as format } from './index6.js';
import { g as getTime } from './index7.js';

const timepickerCss = ".owc-timepicker{width:15.625rem}.owc-timepicker owc-input{min-width:inherit !important;max-width:inherit !important;width:inherit !important}.owc-timepicker owc-input input{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:100%}.owc-timepicker owc-menu{-webkit-box-shadow:none;box-shadow:none}.owc-timepicker owc-menu owc-popover{overflow:hidden}.owc-timepicker owc-menu .owc-timepicker__menu-header{padding:0}";

var __decorate = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __rest = (undefined && undefined.__rest) || function (s, e) {
  var t = {};
  for (var p in s)
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
      t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function")
    for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
        t[p[i]] = s[p[i]];
    }
  return t;
};
const DEFAULT_STEP = 60;
const TAG_LIST_ITEM = 'owc-list-item';
const Timepicker = /*@__PURE__*/ proxyCustomElement(class Timepicker extends HTMLElement {
  constructor() {
    super();
    this.__registerHost();
    this.clickOutside = createEvent(this, "clickOutside", 3);
    this.valueChange = createEvent(this, "valueChange", 3);
    this.visibleChange = createEvent(this, "visibleChange", 3);
    this.validityChange = createEvent(this, "validityChange", 3);
    this.clearClick = createEvent(this, "clearClick", 3);
    this.inputValueChange = createEvent(this, "inputValueChange", 3);
    this.menuRef = useRef();
    this.resetRequested = false;
    this.selectableTimesFormatted = [];
    this.lastEmittedValidityState = undefined;
    this.inputRef = useRef();
    this.validator = new Validator();
    this.referenceDate = moveTimeToTodayUTC(set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }), Date.now());
    this.nativeInputRef = useRef();
    this.listRef = useRef();
    this.component = new ComponentUtils(this, { patchFocusMethod: () => this.focusInputElement() });
    this.onClosestFormReset = () => {
      if (!this.disableValidation) {
        this.resetRequested = this.inputText !== '';
        this.validity = { state: 'valid' };
        this.setValue(null);
        this.inputText = '';
      }
    };
    this.setValue = (newValue) => {
      if (!isTimeEqual(newValue, this.value)) {
        if (this.controlled) {
          this.valueChange.emit(newValue);
        }
        else {
          this.value = newValue;
        }
      }
    };
    this.emitValidityChangeEvent = (newValidity) => {
      var _a, _b;
      if ((newValidity === null || newValidity === void 0 ? void 0 : newValidity.state) !== ((_a = this.lastEmittedValidityState) === null || _a === void 0 ? void 0 : _a.state) ||
        !isSameArray(newValidity === null || newValidity === void 0 ? void 0 : newValidity.type, (_b = this.lastEmittedValidityState) === null || _b === void 0 ? void 0 : _b.type)) {
        this.lastEmittedValidityState = newValidity;
        this.validityChange.emit(newValidity);
      }
    };
    this.isInDisableList = (time) => this.getUnavailableDates().some(dt => {
      const dateUTC = moveTimeToToday(new Date(dt), this.referenceDate);
      return isTimeEqual(dateUTC, time);
    });
    this.focusInputElement = () => { var _a, _b; return !this.disabled && !this.isFocused && ((_b = (_a = this.inputRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.focus()); };
    this.isSearchingNow = () => this.visible;
    this.getListItems = () => { var _a; return Array.from(((_a = this.listRef.current) === null || _a === void 0 ? void 0 : _a.querySelectorAll(TAG_LIST_ITEM)) || []); };
    this.getSearchableListItems = () => this.getListItems().filter(it => it.leaf && hasValue(it.value));
    this.getFormat = () => (this.native ? `${FORMAT_MAP.hour24}:${FORMAT_MAP.minute}` : this.format);
    this.formatDate = (val = this.value, fallback = '') => val && isValid(val)
      ? format(addMinutes(val, new Date().getTimezoneOffset()), this.getFormat(), {
        locale: handleLocation(this.native, this.locale)
      })
      : fallback;
    this.parseDate = (text) => {
      if (!isMatch(text, this.getFormat(), { locale: handleLocation(this.native, this.locale) })) {
        return null;
      }
      const parsedDate = subMinutes(parse(text, this.getFormat(), this.referenceDate, { locale: handleLocation(this.native, this.locale) }), new Date().getTimezoneOffset());
      if (!isValid(parsedDate)) {
        return null;
      }
      if (!this.value || !isTimeEqual(parsedDate, this.value)) {
        return parsedDate;
      }
      else {
        return this.value;
      }
    };
    this.onInputClicked = (ev, originalHandler) => {
      this.visible = true;
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
    };
    this.onInputFocused = (ev, originalHandler) => {
      this.visible = true;
      this.isFocused = true;
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
    };
    this.onInputBlurred = (ev, originalHandler) => {
      this.isFocused = false;
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
    };
    this.onInputValueChanged = (ev, originalHandler) => {
      this.inputText = ev.detail;
      this.updateValueFromInputText();
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
    };
    this.onInputKeyDown = (ev, originalHandler) => {
      if (this.isSearchingNow() && this.allItemsHidden && [KeyboardCode.Enter, KeyboardCode.NumpadEnter].includes(ev.code)) {
        this.visible = false;
      }
      if ([KeyboardCode.Tab].includes(ev.code)) {
        this.visible = false;
        this.isFocused = false;
      }
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
    };
    this.onMenuClickOutside = (ev, originalHandler) => {
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
      if (!ev.defaultPrevented) {
        this.visible = false;
        this.clickOutside.emit();
      }
    };
    this.onClearButtonClicked = (ev, originalHandler) => {
      var _a, _b;
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
      if (!ev.defaultPrevented) {
        stopEvent(ev);
        if (this.visible) {
          this.searchTerm = '';
          this.isSearching = true;
          (_b = (_a = this.inputRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.focus();
        }
        this.inputText = '';
        this.setValue(null);
        this.clearClick.emit();
      }
    };
    this.onListSelectedChanged = (ev, originalHandler) => {
      stopEvent(ev);
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
    };
    this.name = undefined;
    this.disabled = false;
    this.format = 'hh:mm aaa';
    this.label = undefined;
    this.locale = locale;
    this.maxTime = undefined;
    this.minTime = undefined;
    this.native = false;
    this.noClearIcon = false;
    this.disableValidation = false;
    this.placeholder = undefined;
    this.required = false;
    this.selectOutOfStep = false;
    this.size = 'medium';
    this.controlled = false;
    this.step = DEFAULT_STEP;
    this.unavailable = [];
    this.validity = { state: 'valid' };
    this.value = null;
    this.variant = 'filled';
    this.visible = false;
    this.inputProps = undefined;
    this.menuProps = undefined;
    this.selectAllCheckboxState = false;
    this.inputText = '';
    this.isSearching = false;
    this.searchTerm = undefined;
    this.allItemsHidden = undefined;
    this.minTimeVal = undefined;
    this.maxTimeVal = undefined;
    this.isFocused = false;
  }
  onValidatorParamsChange() {
    this.setValidationOptions();
    if (this.value) {
      this.updateInputTextFromValue();
      this.validate();
    }
    else {
      this.updateValueFromInputText();
    }
  }
  setValidationOptions() {
    this.createMinMaxDates();
    this.generateListItems();
    this.formatSelectableTimes();
    this.validator
      .type('time')
      .min(this.minTimeVal)
      .max(this.maxTimeVal)
      .pattern(this.getFormat())
      .locale(this.locale)
      .notOneOf(this.unavailable)
      .oneOf(this.selectableTimesFormatted);
    this.required ? this.validator.required() : this.validator.optional();
  }
  onValueChanged(newValue, oldValue) {
    if (!isTimeEqual(newValue, oldValue)) {
      if (!this.isFocused) {
        this.updateInputTextFromValue();
      }
      !this.controlled && this.valueChange.emit(newValue);
    }
  }
  onValidityChange(newValue, oldValue) {
    if ((newValue === null || newValue === void 0 ? void 0 : newValue.state) !== (oldValue === null || oldValue === void 0 ? void 0 : oldValue.state) || !isSameArray(oldValue === null || oldValue === void 0 ? void 0 : oldValue.type, newValue === null || newValue === void 0 ? void 0 : newValue.type)) {
      this.emitValidityChangeEvent(newValue);
      this.inputRef.current && (this.inputRef.current.validity = newValue);
    }
  }
  /** Triggers the validation */
  validate() {
    var _a, _b;
    if (this.resetRequested) {
      this.resetRequested = false;
      return Promise.resolve({ state: 'valid' });
    }
    const validationResult = this.validator.validate(this.inputText);
    let newValidity;
    if (validationResult === null || validationResult === void 0 ? void 0 : validationResult.length) {
      newValidity = { state: 'error', type: validationResult };
    }
    else {
      newValidity = { state: 'valid' };
    }
    if ((newValidity === null || newValidity === void 0 ? void 0 : newValidity.state) !== ((_a = this.validity) === null || _a === void 0 ? void 0 : _a.state) || !isSameArray((_b = this.validity) === null || _b === void 0 ? void 0 : _b.type, newValidity.type)) {
      this.emitValidityChangeEvent(newValidity);
    }
    return Promise.resolve(newValidity);
  }
  createMinMaxDates() {
    this.minTimeVal = this.minTime ? moveTimeToToday(new Date(this.minTime), this.referenceDate) : startOfDayUTC(this.referenceDate);
    this.maxTimeVal = this.maxTime ? moveTimeToToday(new Date(this.maxTime), this.referenceDate) : endOfDayUTC(this.referenceDate);
    // if min > max we default to start & end of the day
    if (this.minTimeVal >= this.maxTimeVal) {
      this.minTimeVal = startOfDayUTC(this.referenceDate);
      this.maxTimeVal = endOfDayUTC(this.referenceDate);
    }
  }
  onDisabledChanged() {
    this.visible = false;
  }
  onVisibleChanged(newValue, oldValue) {
    var _a;
    if (!newValue) {
      this.isSearching = false;
    }
    else {
      this.updateOptions('');
    }
    this.searchTerm = (_a = this.inputText) !== null && _a !== void 0 ? _a : undefined;
    !this.disableValidation && !newValue && this.validate();
    !!newValue !== !!oldValue && this.visibleChange.emit(!!newValue);
  }
  onSearchTermChanged() {
    this.isSearching && this.updateOptions(this.searchTerm);
  }
  formatSelectableTimes() {
    const _selectableTimesFormatted = [];
    if (!this.selectOutOfStep) {
      getTimeInterval(this.step, this.minTimeVal, this.maxTimeVal).forEach(item => {
        const dateInMs = getTime(item);
        !this.selectOutOfStep && _selectableTimesFormatted.push(dateInMs);
      });
    }
    this.selectableTimesFormatted = _selectableTimesFormatted;
  }
  updateInputTextFromValue() {
    if (this.value && isValid(this.value)) {
      this.inputText = this.formatDate(this.value);
    }
    else {
      this.inputText = '';
    }
  }
  updateValueFromInputText() {
    if (!this.isSearching && this.inputText !== this.formatDate(this.value, '')) {
      this.isSearching = true;
    }
    this.isSearching && (this.searchTerm = this.inputText);
    !this.disableValidation && this.validate();
    this.setValue(this.parseDate(this.inputText));
    this.inputValueChange.emit(this.inputText);
  }
  componentDidLoad() {
    this.updateInputTextFromValue();
    this.setValidationOptions();
  }
  connectedCallback() {
    var _a;
    (_a = this.hostElement.closest('form')) === null || _a === void 0 ? void 0 : _a.addEventListener('reset', this.onClosestFormReset);
  }
  disconnectedCallback() {
    var _a;
    (_a = this.hostElement.closest('form')) === null || _a === void 0 ? void 0 : _a.removeEventListener('reset', this.onClosestFormReset);
  }
  generateListItems() {
    return getTimeInterval(this.step, this.minTimeVal, this.maxTimeVal).map(item => {
      const dateText = format(addMinutes(item, new Date().getTimezoneOffset()), this.getFormat() || defineFormat(this.native, this.referenceDate), {
        locale: handleLocation(this.native, this.locale)
      });
      return (h("owc-list-item", { key: dateText, value: item, disabled: this.isInDisableList(item), onClick: e => {
          this.setValue(this.parseDate(dateText));
          const srcElement = findElementInEventPath(e, 'owc-list-item');
          (srcElement === null || srcElement === void 0 ? void 0 : srcElement.value) === this.value && (this.visible = false);
        } }, dateText));
    });
  }
  async updateOptions(input) {
    // TODO: debounce should be implemented for performance improvement
    const searchableItems = this.getSearchableListItems();
    const searchTerm = (input || '').toLowerCase();
    let hiddenCount = 0;
    for (const item of searchableItems) {
      let hide = false;
      if (searchTerm) {
        const searchKey = item.searchKey || (await item.getTextContent());
        hide = !searchKey.toLowerCase().includes(searchTerm);
      }
      item.hidden = hide;
      hide && (hiddenCount += 1);
    }
    this.allItemsHidden = hiddenCount && hiddenCount === searchableItems.length;
  }
  getUnavailableDates() {
    var _a;
    return stringToNumberArray(typeof this.unavailable === 'string' ? this.unavailable : (_a = this.unavailable) === null || _a === void 0 ? void 0 : _a.map(v => `${v}`));
  }
  getInputElement() {
    const _a = this.inputProps || {}, { ref: inputRef } = _a, _b = _a.nativeInputProps, _c = _b === void 0 ? {} : _b, { ref: nativeInputRef = undefined } = _c, nativeInputProps = __rest(_c, ["ref"]), _d = _a.clearIconProps, _e = _d === void 0 ? {} : _d, { onclick: originalClearIconClick = undefined } = _e, clearIconProps = __rest(_e, ["onclick"]), { onfocus: originalOnInputFocus, onblur: originalOnInputBlur, onclick: originalOnInputClick, onValueChange: originalOnInputValueChange, onkeydown: originalOnInputKeyDown } = _a, inputProps = __rest(_a, ["ref", "nativeInputProps", "clearIconProps", "onfocus", "onblur", "onclick", "onValueChange", "onkeydown"]);
    return (h("owc-input", Object.assign({ name: this.name, ref: el => this.inputRef(el, [inputRef]), hostRole: "combobox" }, accessibilityAttributes({ autocomplete: 'list' }), { disabled: this.disabled, label: this.label, disableValidation: true, placeholder: this.placeholder, required: this.required, pattern: this.getFormat(), variant: this.variant, size: this.size, type: "time", validity: this.validity, noClearIcon: this.noClearIcon, nativeInputProps: Object.assign(Object.assign({ readOnly: !this.isSearchingNow(), ref: el => this.nativeInputRef(el, [nativeInputRef]), pattern: undefined }, nativeInputProps), (!this.native ? { type: 'text' } : {})), clearIconProps: Object.assign({ onclick: e => this.onClearButtonClicked(e, originalClearIconClick) }, clearIconProps) }, inputProps, { value: this.inputText, focusedView: this.visible, role: "combobox", onFocus: e => this.onInputFocused(e, originalOnInputFocus), onBlur: e => this.onInputBlurred(e, originalOnInputBlur), onClick: e => this.onInputClicked(e, originalOnInputClick), onValueChange: e => this.onInputValueChanged(e, originalOnInputValueChange), onKeyDown: e => this.onInputKeyDown(e, originalOnInputKeyDown), onValidityChange: stopEvent }), h("slot", { name: "assistive-text" }), h("owc-icon", { name: "clock", slot: "prefix" })));
  }
  getMenuElement(width) {
    const _a = this.menuProps || {}, { ref: menuRef, style: menuStyle } = _a, _b = _a.listProps, _c = _b === void 0 ? {} : _b, { ref: listRef = undefined, onSelectedChange = undefined } = _c, listProps = __rest(_c, ["ref", "onSelectedChange"]), { onClickOutside } = _a, menuProps = __rest(_a, ["ref", "style", "listProps", "onClickOutside"]);
    return (h("owc-menu", Object.assign({ style: Object.assign({ width: `${width}px`, minWidth: `${width}px`, maxWidth: `${width}px` }, menuStyle), position: "bottom", trigger: "manual", strategy: "fixed", ref: el => this.menuRef(el, [menuRef]), autoCloseOnSelection: true }, menuProps, { listProps: Object.assign(Object.assign({ selected: [getTime(this.value)], onSelectedChange: ev => this.onListSelectedChanged(ev, onSelectedChange), ref: el => this.listRef(el, [listRef]), hostRole: 'listbox' }, accessibilityAttributes({ required: this.required, readonly: !this.isSearchingNow() })), listProps), anchor: this.inputRef.current, visible: this.visible, onVisibleChange: e => {
        if (!e.detail && this.value && isValid(this.value)) {
          this.inputText = this.formatDate(this.value);
        }
        this.visible = e.detail;
      }, onClickOutside: e => this.onMenuClickOutside(e, onClickOutside) }), this.generateListItems()));
  }
  render() {
    var _a, _b, _c, _d;
    const width = ((_c = (_b = (_a = this.inputRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect()) === null || _c === void 0 ? void 0 : _c.width) || ((_d = this.hostElement.getBoundingClientRect()) === null || _d === void 0 ? void 0 : _d.width) || 0;
    const hostClasses = this.component.getHostBemClasses({ [`variant-${this.variant || 'filled'}`]: true, editing: this.isSearchingNow() });
    return (h(Host, Object.assign({ class: hostClasses, onFocus: this.focusInputElement, tabIndex: this.disabled ? -1 : this.component.getInitialTabIndex() }, accessibility({ role: 'region', aria: { label: this.label } })), this.getInputElement(), this.getMenuElement(width)));
  }
  get hostElement() { return this; }
  static get watchers() { return {
    "step": ["onValidatorParamsChange"],
    "minTime": ["onValidatorParamsChange"],
    "maxTime": ["onValidatorParamsChange"],
    "format": ["onValidatorParamsChange"],
    "locale": ["onValidatorParamsChange"],
    "required": ["onValidatorParamsChange"],
    "unavailable": ["onValidatorParamsChange"],
    "selectOutOfStep": ["onValidatorParamsChange"],
    "value": ["onValueChanged"],
    "validity": ["onValidityChange"],
    "disabled": ["onDisabledChanged"],
    "visible": ["onVisibleChanged"],
    "searchTerm": ["onSearchTermChanged"]
  }; }
  static get style() { return timepickerCss; }
}, [4, "owc-timepicker", {
    "name": [513],
    "disabled": [516],
    "format": [513],
    "label": [513],
    "locale": [16],
    "maxTime": [514, "max-time"],
    "minTime": [514, "min-time"],
    "native": [516],
    "noClearIcon": [516, "no-clear-icon"],
    "disableValidation": [4, "disable-validation"],
    "placeholder": [513],
    "required": [516],
    "selectOutOfStep": [516, "select-out-of-step"],
    "size": [513],
    "controlled": [516],
    "step": [514],
    "unavailable": [16],
    "validity": [16],
    "value": [1538],
    "variant": [513],
    "visible": [1028],
    "inputProps": [16],
    "menuProps": [16],
    "selectAllCheckboxState": [32],
    "inputText": [32],
    "isSearching": [32],
    "searchTerm": [32],
    "allItemsHidden": [32],
    "minTimeVal": [32],
    "maxTimeVal": [32],
    "isFocused": [32],
    "validate": [64]
  }]);
__decorate([
  DefaultSlotWrapper()
], Timepicker.prototype, "menuRef", void 0);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-timepicker", "owc-card", "owc-checkbox", "owc-divider", "owc-icon", "owc-icon-button", "owc-input", "owc-list", "owc-list-item", "owc-menu", "owc-popover", "owc-radio", "owc-tooltip"];
  components.forEach(tagName => { switch (tagName) {
    case "owc-timepicker":
      if (!customElements.get(tagName)) {
        customElements.define(tagName, Timepicker);
      }
      break;
    case "owc-card":
      if (!customElements.get(tagName)) {
        defineCustomElement$d();
      }
      break;
    case "owc-checkbox":
      if (!customElements.get(tagName)) {
        defineCustomElement$c();
      }
      break;
    case "owc-divider":
      if (!customElements.get(tagName)) {
        defineCustomElement$b();
      }
      break;
    case "owc-icon":
      if (!customElements.get(tagName)) {
        defineCustomElement$a();
      }
      break;
    case "owc-icon-button":
      if (!customElements.get(tagName)) {
        defineCustomElement$9();
      }
      break;
    case "owc-input":
      if (!customElements.get(tagName)) {
        defineCustomElement$8();
      }
      break;
    case "owc-list":
      if (!customElements.get(tagName)) {
        defineCustomElement$7();
      }
      break;
    case "owc-list-item":
      if (!customElements.get(tagName)) {
        defineCustomElement$6();
      }
      break;
    case "owc-menu":
      if (!customElements.get(tagName)) {
        defineCustomElement$5();
      }
      break;
    case "owc-popover":
      if (!customElements.get(tagName)) {
        defineCustomElement$4();
      }
      break;
    case "owc-radio":
      if (!customElements.get(tagName)) {
        defineCustomElement$3();
      }
      break;
    case "owc-tooltip":
      if (!customElements.get(tagName)) {
        defineCustomElement$2();
      }
      break;
  } });
}

const OwcTimepicker = Timepicker;
const defineCustomElement = defineCustomElement$1;

export { OwcTimepicker, defineCustomElement };
