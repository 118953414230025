import { proxyCustomElement, HTMLElement, createEvent, h, Host } from '@stencil/core/internal/client';
import { c as getDesignTokenValue, C as ComponentUtils } from './ComponentUtils.js';
import { a as getBody, A as isPositiveNumber } from './element.js';
import { a as alpha } from './color.js';
import './MutationObserverSafe.js';
import { O as OnElementMutation } from './element-mutation.js';

const backdropCss = "*{scrollbar-width:thin;scrollbar-color:var(--one-color-interaction-default-intensity-low) transparent;scroll-margin:initial;scroll-padding:3px}::-webkit-scrollbar{width:12px;height:12px}::-webkit-scrollbar-track{background:transparent}::-webkit-scrollbar-corner{background-color:transparent}::-webkit-scrollbar-thumb{background-color:var(--one-color-interaction-default-intensity-low);border-radius:calc(3px + 4px);border:3px solid rgba(255, 255, 255, 0);background-clip:padding-box}::-webkit-scrollbar-thumb:hover{background-color:var(--one-color-interaction-hover-intensity-medium);border-radius:calc(3px + 4px);border:3px solid rgba(255, 255, 255, 0);background-clip:padding-box}::-webkit-scrollbar-thumb:active{background:var(--one-color-interaction-focus-intensity-higher);border-radius:calc(3px + 4px);border:3px solid rgba(255, 255, 255, 0);background-clip:padding-box}.owc-backdrop{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;min-height:100%;max-height:100%;height:100%;min-width:100%;max-width:100%;width:100%;-webkit-box-sizing:border-box;box-sizing:border-box;position:fixed;top:0;left:0;right:0;bottom:0;background-color:var(--backdrop-opacity);padding:1.5rem;overflow:hidden;-webkit-transition:all 0.5s;transition:all 0.5s;z-index:var(--one-z-index-backdrop)}.owc-backdrop.owc-backdrop--hidden{display:none}.owc-backdrop .owc-backdrop__backdrop-content{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;display:contents;max-height:100%;max-width:100%;overflow:hidden;-webkit-transition:all 0.5s;transition:all 0.5s}";

var __decorate = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
const DEFAULT_OPACITY = parseFloat(getDesignTokenValue('--one-backdrop-opacity'));
const BACKDROP_OPACITY_VARIABLE = '--backdrop-opacity';
const STYLE_CLEAR_TIMEOUT = 100;
const Backdrop = /*@__PURE__*/ proxyCustomElement(class Backdrop extends HTMLElement {
  constructor() {
    super();
    this.__registerHost();
    this.backdropClick = createEvent(this, "backdropClick", 3);
    this.component = new ComponentUtils(this);
    this.handleBodyOverflow = () => {
      var _a, _b, _c, _d;
      if (!this.disableScrollLock && !this.hidden) {
        (_b = (_a = getBody()) === null || _a === void 0 ? void 0 : _a.style) === null || _b === void 0 ? void 0 : _b.setProperty('overflow', 'hidden', 'important');
      }
      else {
        (_d = (_c = getBody()) === null || _c === void 0 ? void 0 : _c.style) === null || _d === void 0 ? void 0 : _d.removeProperty('overflow');
      }
    };
    this.setOpacityForBackDrop = () => {
      var _a, _b;
      const opacityValue = this.opacityOverriden && isPositiveNumber(this.opacity) ? this.opacity : parseFloat(getDesignTokenValue('--one-backdrop-opacity'));
      const backdropBackgroundColor = getDesignTokenValue('--one-color-background-overly');
      (_b = (_a = this.hostElement) === null || _a === void 0 ? void 0 : _a.style) === null || _b === void 0 ? void 0 : _b.setProperty(BACKDROP_OPACITY_VARIABLE, `${alpha(backdropBackgroundColor, opacityValue)}`);
    };
    this.handleMouseDown = (ev) => (this.backdropClicked = ev.target === ev.currentTarget);
    this.onBackdropClicked = () => {
      this.backdropClicked && this.backdropClick.emit();
      this.backdropClicked = false;
    };
    this.opacity = DEFAULT_OPACITY;
    this.disableScrollLock = false;
    this.hidden = false;
    this.opacityOverriden = false;
  }
  handleThemeChanged() {
    this.setOpacityForBackDrop();
  }
  onContentChange() {
    this.handleBodyOverflow();
  }
  handleDisableScrollLockChanges() {
    this.handleBodyOverflow();
  }
  handleOpacityChange() {
    this.opacityOverriden = true;
    this.setOpacityForBackDrop();
  }
  connectedCallback() {
    clearTimeout(this.timeoutID);
    this.handleDisableScrollLockChanges();
  }
  componentWillLoad() {
    this.opacityOverriden = !this.opacity;
  }
  componentDidLoad() {
    this.handleDisableScrollLockChanges();
    this.setOpacityForBackDrop();
  }
  disconnectedCallback() {
    this.timeoutID = setTimeout(() => {
      var _a, _b;
      (_b = (_a = getBody()) === null || _a === void 0 ? void 0 : _a.style) === null || _b === void 0 ? void 0 : _b.removeProperty('overflow');
    }, STYLE_CLEAR_TIMEOUT);
  }
  render() {
    const hostClasses = this.component.getHostBemClasses({ hidden: this.hidden });
    const contentClasses = this.component.getChildBemClasses('backdrop-content', hostClasses);
    return (h(Host, { class: hostClasses, tabIndex: this.hidden ? -1 : this.component.getInitialTabIndex(), onClick: this.onBackdropClicked, onMouseDown: this.handleMouseDown }, h("div", { class: contentClasses }, h("slot", { ref: el => {
        this.defaultSlotRef = el;
        this.onContentChange();
      } }))));
  }
  get hostElement() { return this; }
  static get watchers() { return {
    "hidden": ["handleDisableScrollLockChanges"],
    "disableScrollLock": ["handleDisableScrollLockChanges"],
    "opacity": ["handleOpacityChange"]
  }; }
  static get style() { return backdropCss; }
}, [4, "owc-backdrop", {
    "opacity": [514],
    "disableScrollLock": [516, "disable-scroll-lock"],
    "hidden": [516],
    "opacityOverriden": [32]
  }, [[4, "themeChanged", "handleThemeChanged"]]]);
__decorate([
  OnElementMutation({ target: self => self.hostElement, attributes: true, childList: true, subtree: true, characterData: true })
], Backdrop.prototype, "onContentChange", null);
function defineCustomElement() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-backdrop"];
  components.forEach(tagName => { switch (tagName) {
    case "owc-backdrop":
      if (!customElements.get(tagName)) {
        customElements.define(tagName, Backdrop);
      }
      break;
  } });
}

export { Backdrop as B, defineCustomElement as d };
