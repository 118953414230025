import { proxyCustomElement, HTMLElement, createEvent, h, Host } from '@stencil/core/internal/client';
import { k as stopEvent, t as isDefined, h as hasEllipsis, x as hasValue } from './element.js';
import { i as isSameArray } from './array.js';
import { V as Validator } from './long-press.js';
import { a as accessibilityAttributes } from './accessibility.js';
import { j as KeyboardCode } from './MutationObserverSafe.js';
import { u as useRef } from './ref.js';
import { a as DefaultSlotWrapper, C as ComponentUtils } from './ComponentUtils.js';
import { d as defineCustomElement$g } from './button.js';
import { d as defineCustomElement$f } from './card.js';
import { d as defineCustomElement$e } from './checkbox.js';
import { d as defineCustomElement$d } from './component-footer.js';
import { d as defineCustomElement$c } from './component-header.js';
import { d as defineCustomElement$b } from './divider.js';
import { d as defineCustomElement$a } from './icon.js';
import { d as defineCustomElement$9 } from './icon-button.js';
import { d as defineCustomElement$8 } from './input.js';
import { d as defineCustomElement$7 } from './list.js';
import { d as defineCustomElement$6 } from './list-item.js';
import { d as defineCustomElement$5 } from './menu.js';
import { d as defineCustomElement$4 } from './popover.js';
import { d as defineCustomElement$3 } from './radio.js';
import { d as defineCustomElement$2 } from './tooltip.js';
import { d as defineCustomElement$1 } from './typography.js';

const baseDropdownCss = ".owc-base-dropdown{width:inherit;display:-ms-inline-flexbox;display:inline-flex;}.owc-base-dropdown.owc-base-dropdown--loading owc-list-item{visibility:hidden}.owc-base-dropdown owc-input{min-width:inherit !important;max-width:inherit !important;width:inherit !important}.owc-base-dropdown owc-input input{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:100%}.owc-base-dropdown owc-menu{-webkit-box-shadow:none;box-shadow:none}.owc-base-dropdown owc-menu owc-popover{overflow:hidden}.owc-base-dropdown owc-input .owc-base-dropdown__clear-button{color:inherit !important;display:none}.owc-base-dropdown owc-input .owc-base-dropdown__plus-text{font-family:var(--one-typography-font-family);font-size:var(--one-typography-desktop-subtitle-1-font-size);font-weight:var(--one-typography-desktop-subtitle-1-font-weight);line-height:var(--one-typography-desktop-subtitle-1-line-height);-webkit-font-kerning:var(--one-typography-desktop-subtitle-1-font-kerning);font-kerning:var(--one-typography-desktop-subtitle-1-font-kerning)}.owc-base-dropdown owc-menu owc-card .owc-card__header-wrapper .owc-base-dropdown__menu-header{padding:0}.owc-base-dropdown owc-menu .owc-base-dropdown__menu-footer{padding:1rem}.owc-base-dropdown.owc-base-dropdown--has-clear-button owc-input .owc-base-dropdown__clear-button{display:-ms-flexbox;display:flex}.owc-base-dropdown:not(.owc-base-dropdown--editing) owc-input{cursor:default}.owc-base-dropdown.owc-base-dropdown--disabled owc-input .owc-base-dropdown__menu-trigger-button{background-color:transparent}.owc-base-dropdown.owc-base-dropdown--disabled owc-input .owc-base-dropdown__clear-button{background-color:transparent}";

var __decorate = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __rest = (undefined && undefined.__rest) || function (s, e) {
  var t = {};
  for (var p in s)
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
      t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function")
    for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
        t[p[i]] = s[p[i]];
    }
  return t;
};
const MENU_ITEM_LIMIT = 6;
const TAG_LIST_ITEM = 'owc-list-item';
const TOOLTIP_OFFSET = 2;
const BaseDropdown = /*@__PURE__*/ proxyCustomElement(class BaseDropdown extends HTMLElement {
  constructor() {
    super();
    this.__registerHost();
    this.valueChange = createEvent(this, "valueChange", 3);
    this.visibleChange = createEvent(this, "visibleChange", 3);
    this.clickOutside = createEvent(this, "clickOutside", 3);
    this.clearClick = createEvent(this, "clearClick", 3);
    this.validityChange = createEvent(this, "validityChange", 3);
    this.itemAdd = createEvent(this, "itemAdd", 3);
    this.defaultSlotWrapper = useRef();
    this.validator = new Validator();
    this.nativeInputRef = useRef();
    this.component = new ComponentUtils(this, { patchFocusMethod: () => this.focusInputElement() });
    this.lastEmittedValidityState = undefined;
    this.resetRequested = false;
    this.focusInputElement = () => { var _a; return !this.disabled && !this.isFocused && ((_a = this.inputRef) === null || _a === void 0 ? void 0 : _a.focus()); };
    this.isVisible = () => !this.disabled && this.visible;
    this.isSearchable = () => this.searchable || this.editable;
    this.isSearchingNow = () => this.isSearchable() && this.isVisible();
    this.isSelectOnApplyVisible = () => this.multiple && this.showApplyButton;
    this.getListItems = () => { var _a; return Array.from(((_a = this.listRef) === null || _a === void 0 ? void 0 : _a.querySelectorAll(TAG_LIST_ITEM)) || []); };
    this.onClosestFormReset = () => {
      if (!this.disableValidation) {
        this.resetRequested = !(isSameArray([], this.value) || !this.value);
        this.validity = { state: 'valid' };
        this.setValue([]);
      }
    };
    this.setValue = (newValue) => {
      if (!isSameArray(newValue, this.value)) {
        if (!this.controlled) {
          this.value = [...newValue];
        }
        else {
          this.valueChange.emit(newValue);
        }
      }
    };
    this.emitValidityChangeEvent = (newValidity) => {
      var _a, _b;
      if ((newValidity === null || newValidity === void 0 ? void 0 : newValidity.state) !== ((_a = this.lastEmittedValidityState) === null || _a === void 0 ? void 0 : _a.state) ||
        !isSameArray(newValidity === null || newValidity === void 0 ? void 0 : newValidity.type, (_b = this.lastEmittedValidityState) === null || _b === void 0 ? void 0 : _b.type)) {
        this.lastEmittedValidityState = newValidity;
        this.validityChange.emit(newValidity);
      }
    };
    this.onInputClicked = (ev, originalHandler) => {
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
      if (!ev.defaultPrevented) {
        this.visible = true;
      }
    };
    this.onInputFocused = (ev, originalHandler) => {
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
      if (!ev.defaultPrevented) {
        this.visible = true;
        this.isFocused = true;
        this.hostElement.dispatchEvent(new KeyboardEvent('focus'));
      }
    };
    this.onInputBlurred = (ev, originalHandler) => {
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
      if (!ev.defaultPrevented) {
        this.isFocused = false;
        this.hostElement.dispatchEvent(new KeyboardEvent('blur'));
      }
    };
    this.onInputValueChanged = (ev, originalHandler) => {
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
      if (!ev.defaultPrevented) {
        this.isSearchingNow() && (this.searchTerm = ev.detail);
      }
    };
    this.onInputMouseOut = (ev, originalHandler) => {
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
      if (!ev.defaultPrevented) {
        this.tooltipVisible = false;
      }
    };
    this.onInputMouseOver = (ev, originalHandler) => {
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
      if (!ev.defaultPrevented) {
        this.shouldTooltipRender() && (this.tooltipVisible = true);
      }
    };
    this.onInputKeyDown = (ev, originalHandler) => {
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
      const isAddingAllowed = this.editable && (this.addItemOnMatch ? true : this.allItemsHidden);
      if (this.isSearchingNow() && isAddingAllowed && [KeyboardCode.Enter, KeyboardCode.NumpadEnter].includes(ev.code)) {
        this.itemAdd.emit(ev.target.value);
        this.visible = false;
      }
      if ([KeyboardCode.Tab].includes(ev.code)) {
        this.visible = false;
        this.isFocused = false;
      }
      return true;
    };
    this.onMenuClickOutside = (ev, originalHandler) => {
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
      if (!ev.defaultPrevented) {
        this.visible = false;
        this.clickOutside.emit();
      }
    };
    this.onClearButtonClicked = (ev, originalHandler) => {
      var _a;
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
      if (!ev.defaultPrevented) {
        stopEvent(ev);
        if (this.isSearchingNow()) {
          this.searchTerm = undefined;
          (_a = this.inputRef) === null || _a === void 0 ? void 0 : _a.focus();
        }
        else {
          this.setValue([]);
          this.copyValueToActiveSelections();
          this.clearClick.emit();
        }
      }
    };
    this.onMenuTriggerButtonClicked = (ev, originalHandler) => {
      var _a;
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
      if (!ev.defaultPrevented) {
        stopEvent(ev);
        this.visible = !this.visible;
        this.visible && ((_a = this.inputRef) === null || _a === void 0 ? void 0 : _a.focus());
      }
    };
    this.onApplyButtonClicked = () => {
      this.copyActiveSelectionsToValue();
      this.visible = false;
    };
    this.onSelectAllClicked = () => {
      if (this.selectAllCheckboxState === true) {
        if (this.isSelectOnApplyVisible()) {
          this.activeSelections = [];
        }
        else {
          this.setValue([]);
        }
        this.selectAllCheckboxState = false;
      }
      else {
        const allItemValues = this.getSelectableListItems()
          .map(item => item.value)
          .filter(isDefined);
        if (this.isSelectOnApplyVisible()) {
          this.activeSelections = [...allItemValues];
        }
        else {
          this.setValue(allItemValues);
        }
        this.selectAllCheckboxState = true;
      }
    };
    this.onListSelectedChanged = (ev, originalHandler) => {
      stopEvent(ev);
      if (this.isSelectOnApplyVisible()) {
        this.activeSelections = [...(ev.detail || [])];
      }
      else {
        this.setValue(ev.detail);
      }
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
    };
    this.visible = false;
    this.validity = { state: 'valid' };
    this.disabled = false;
    this.label = undefined;
    this.limit = MENU_ITEM_LIMIT;
    this.name = undefined;
    this.placeholder = undefined;
    this.required = false;
    this.value = [];
    this.variant = 'filled';
    this.size = 'medium';
    this.rounded = false;
    this.searchable = false;
    this.editable = false;
    this.notFoundText = 'Not Found';
    this.noClearIcon = false;
    this.disableValidation = false;
    this.multiple = false;
    this.selectAllText = 'All';
    this.hideSelectAll = false;
    this.addItemOnMatch = false;
    this.showApplyButton = false;
    this.applyButtonText = 'Apply';
    this.minlength = undefined;
    this.maxlength = undefined;
    this.notSelectableValues = [];
    this.selectableValues = [];
    this.controlled = false;
    this.inputProps = undefined;
    this.clearButtonProps = undefined;
    this.menuTriggerButtonProps = undefined;
    this.menuProps = undefined;
    this.tooltipProps = undefined;
    this.selectAllCheckboxState = false;
    this.selectedItemTexts = [];
    this.searchTerm = undefined;
    this.allItemsHidden = undefined;
    this.activeSelections = [];
    this.tooltipVisible = false;
    this.isFocused = false;
    this.inputRef = undefined;
    this.listRef = undefined;
    this.loading = true;
  }
  onValueChanged(newValue, oldValue) {
    if (!isSameArray(newValue, oldValue)) {
      this.copyValueToActiveSelections();
      !this.controlled && this.valueChange.emit(newValue);
      !this.disableValidation && this.validate();
    }
  }
  onDisabledChanged() {
    this.visible = false;
  }
  onVisibleChanged(newValue, oldValue) {
    this.searchTerm = undefined;
    this.tooltipVisible = false;
    this.copyValueToActiveSelections();
    !this.disableValidation && !newValue && this.validate();
    !!newValue !== !!oldValue && this.visibleChange.emit(!!newValue);
  }
  onValidityChange(newValue, oldValue) {
    if ((newValue === null || newValue === void 0 ? void 0 : newValue.state) !== (oldValue === null || oldValue === void 0 ? void 0 : oldValue.state) || !isSameArray(oldValue === null || oldValue === void 0 ? void 0 : oldValue.type, newValue === null || newValue === void 0 ? void 0 : newValue.type)) {
      this.emitValidityChangeEvent(newValue);
      this.inputRef && (this.inputRef.validity = newValue);
    }
  }
  onValidatorParamsChange() {
    this.validator
      .type('select')
      .minLength(this.multiple ? this.minlength : undefined)
      .maxLength(this.multiple ? this.maxlength : undefined)
      .notOneOf(this.notSelectableValues)
      .oneOf(this.selectableValues);
    this.required ? this.validator.required() : this.validator.optional();
  }
  async onSearchTermChanged() {
    // TODO: debounce should be implemented for performance improvement
    const searchableItems = this.getSearchableListItems();
    const searchTerm = (this.searchTerm || '').toLowerCase();
    let hiddenCount = 0;
    for (const item of searchableItems) {
      let hide = false;
      if (searchTerm) {
        const searchKey = item.searchKey || (await item.getTextContent());
        hide = !searchKey.toLowerCase().includes(searchTerm);
      }
      item.hidden = hide;
      hide && (hiddenCount += 1);
    }
    this.getListItems().forEach(el => {
      el.updateInternalStates();
    });
    this.allItemsHidden = hiddenCount === searchableItems.length;
  }
  /** Triggers the validation */
  validate() {
    var _a, _b;
    if (this.resetRequested) {
      this.resetRequested = false;
      return Promise.resolve({ state: 'valid' });
    }
    const validationResult = this.validator.validate(this.activeSelections);
    let newValidity;
    if (validationResult === null || validationResult === void 0 ? void 0 : validationResult.length) {
      newValidity = { state: 'error', type: validationResult };
    }
    else {
      newValidity = { state: 'valid' };
    }
    if ((newValidity === null || newValidity === void 0 ? void 0 : newValidity.state) !== ((_a = this.validity) === null || _a === void 0 ? void 0 : _a.state) || !isSameArray((_b = this.validity) === null || _b === void 0 ? void 0 : _b.type, newValidity.type)) {
      this.emitValidityChangeEvent(newValidity);
    }
    return Promise.resolve(newValidity);
  }
  componentDidLoad() {
    this.updateSelectedItemTexts();
    this.onValidatorParamsChange();
    this.onValidityChange();
    this.loading = false;
  }
  componentWillLoad() {
    this.copyValueToActiveSelections();
  }
  componentWillRender() {
    this.visible && (this.selectAllCheckboxState = this.getSelectAllCheckboxState(this.getListItems()));
    this.updateSelectedItemTexts();
  }
  connectedCallback() {
    var _a;
    (_a = this.hostElement.closest('form')) === null || _a === void 0 ? void 0 : _a.addEventListener('reset', this.onClosestFormReset);
  }
  disconnectedCallback() {
    var _a;
    (_a = this.hostElement.closest('form')) === null || _a === void 0 ? void 0 : _a.removeEventListener('reset', this.onClosestFormReset);
  }
  shouldTooltipRender() {
    if (this.multiple && this.selectedItemTexts.length > 1) {
      return true;
    }
    else {
      return hasEllipsis(this.nativeInputRef.current);
    }
  }
  getSelectableListItems(list) {
    return (list || this.getListItems()).filter(it => !it.notSelectable);
  }
  getSelectedListItems() {
    var _a;
    const values = this.value || [];
    return values.length
      ? Array.from(((_a = this.listRef) === null || _a === void 0 ? void 0 : _a.querySelectorAll(values.map(v => `${TAG_LIST_ITEM}[value="${v}"]`).join(', '))) || [])
      : [];
  }
  getSearchableListItems(list) {
    return (list || this.getListItems()).filter(it => it.leaf && hasValue(it.value));
  }
  copyValueToActiveSelections() {
    !isSameArray(this.activeSelections, this.value) && (this.activeSelections = [...this.value].filter(isDefined));
  }
  copyActiveSelectionsToValue() {
    !isSameArray(this.activeSelections, this.value) && this.setValue([...this.activeSelections].filter(isDefined));
  }
  async updateSelectedItemTexts() {
    return Promise.all(this.getSelectedListItems().map(item => item.getTextContent())).then(texts => !isSameArray(texts, this.selectedItemTexts) && (this.selectedItemTexts = [...texts]));
  }
  getSelectAllCheckboxState(items) {
    var _a, _b;
    const selectableItems = this.getSelectableListItems(items);
    const selectedItemsLength = (_b = (_a = selectableItems.filter(item => hasValue(item.value) && (this.activeSelections || []).includes(item.value))) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0;
    if (selectableItems.length) {
      if (selectedItemsLength >= selectableItems.length) {
        return true;
      }
      else {
        return selectedItemsLength === 0 ? false : 'indeterminate';
      }
    }
    else {
      return false;
    }
  }
  isSelectedAll() {
    var _a, _b, _c;
    const selectableItems = this.getSelectableListItems();
    const selectedItemsLength = this.showApplyButton
      ? (_a = this.value) === null || _a === void 0 ? void 0 : _a.length
      : (_c = (_b = selectableItems.filter(item => hasValue(item.value) && (this.value || []).includes(item.value))) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0;
    if (selectableItems.length && selectedItemsLength >= selectableItems.length) {
      return true;
    }
    else {
      return false;
    }
  }
  getInputValue() {
    if (this.isSearchingNow()) {
      return this.searchTerm;
    }
    else {
      if (this.multiple && this.isSelectedAll()) {
        return this.selectAllText || 'All';
      }
      return this.selectedItemTexts[0];
    }
  }
  getInputTooltipText() {
    return (this.selectedItemTexts || []).join(', ');
  }
  getMoreItemsText() {
    const selectedOptionCount = (this.value || []).length;
    if (this.multiple && !this.isSearchingNow() && selectedOptionCount > 1 && !this.isSelectedAll()) {
      return `+${this.value.length - 1}`;
    }
    return undefined;
  }
  getInputElement(hostClasses, inputValue) {
    const plusText = this.getMoreItemsText();
    const _a = this.inputProps || {}, { ref: inputRef } = _a, _b = _a.nativeInputProps, _c = _b === void 0 ? {} : _b, { ref: nativeInputRef = undefined } = _c, nativeInputProps = __rest(_c, ["ref"]), { onfocus: originalOnInputFocus, onblur: originalOnInputBlur, onclick: originalOnInputClick, onValueChange: originalOnInputValueChange, onmouseout: originalOnInputMouseOut, onmouseover: originalOnInputMouseOver, onkeydown: originalOnInputKeyDown } = _a, inputProps = __rest(_a, ["ref", "nativeInputProps", "onfocus", "onblur", "onclick", "onValueChange", "onmouseout", "onmouseover", "onkeydown"]);
    const _d = this.clearButtonProps || {}, { className: clearButtonClassName, onclick: originalClearButtonClick } = _d, clearButtonProps = __rest(_d, ["className", "onclick"]);
    const _e = this.menuTriggerButtonProps || {}, { className: menuTriggerButtonClassName, onclick: originalMenuTriggerButtonClick } = _e, menuTriggerButtonProps = __rest(_e, ["className", "onclick"]);
    const menuTriggerButtonClasses = this.component.getChildBemClasses('menu-trigger-button', hostClasses, {}, menuTriggerButtonClassName);
    const clearButtonClasses = this.component.getChildBemClasses('clear-button', hostClasses, {}, clearButtonClassName);
    const plusTextClasses = this.component.getChildBemClasses('plus-text', hostClasses);
    return (h("owc-input", Object.assign({ ref: el => {
        this.inputRef = el;
        inputRef === null || inputRef === void 0 ? void 0 : inputRef(el);
      }, hostRole: "combobox" }, accessibilityAttributes({ autocomplete: this.isSearchable() ? 'list' : 'none' }), { disabled: this.disabled, label: this.label, name: this.name, placeholder: this.placeholder, required: this.required, variant: this.variant, disableValidation: true, size: this.size, rounded: this.rounded, validity: this.validity, noClearIcon: true, nativeInputProps: Object.assign(Object.assign({}, nativeInputProps), { readOnly: !this.isSearchingNow(), ref: el => this.nativeInputRef(el, [nativeInputRef]) }) }, inputProps, { value: inputValue, focusedView: this.isVisible(), role: "combobox", onFocus: e => this.onInputFocused(e, originalOnInputFocus), onBlur: e => this.onInputBlurred(e, originalOnInputBlur), onClick: e => this.onInputClicked(e, originalOnInputClick), onValueChange: e => this.onInputValueChanged(e, originalOnInputValueChange), onMouseOut: e => this.onInputMouseOut(e, originalOnInputMouseOut), onMouseOver: e => this.onInputMouseOver(e, originalOnInputMouseOver), onKeyDown: e => this.onInputKeyDown(e, originalOnInputKeyDown) }), h("slot", { name: "assistive-text" }), h("slot", { name: "prefix" }), h("div", { slot: "suffix" }, h("slot", { name: "suffix" }), plusText ? h("span", { class: plusTextClasses }, plusText) : null, h("owc-icon-button", Object.assign({ class: clearButtonClasses, icon: "circle_clear", family: "outlined", disableRipple: true, disabled: this.disabled, alternate: true, variant: "inner", size: "small" }, clearButtonProps, { onClick: e => this.onClearButtonClicked(e, originalClearButtonClick) })), h("owc-icon-button", Object.assign({ class: menuTriggerButtonClasses, icon: this.isVisible() ? 'caret_up' : 'caret_down', family: "filled", disableRipple: true, disabled: this.disabled, alternate: true, variant: "inner", size: "small" }, menuTriggerButtonProps, { onClick: e => this.onMenuTriggerButtonClicked(e, originalMenuTriggerButtonClick) })))));
  }
  getInputTooltipElement(hostClasses) {
    var _a, _b, _c;
    if (!this.shouldTooltipRender()) {
      return null;
    }
    else {
      const _d = this.tooltipProps || {}, { style: tooltipStyle, className: tooltipClassName } = _d, tooltipProps = __rest(_d, ["style", "className"]);
      const tooltipClasses = this.component.getChildBemClasses('input-tooltip', hostClasses, {}, tooltipClassName);
      const tooltipContent = this.getInputTooltipText();
      const width = ((_b = (_a = this.inputRef) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) === null || _b === void 0 ? void 0 : _b.width) || ((_c = this.hostElement.getBoundingClientRect()) === null || _c === void 0 ? void 0 : _c.width) || 0;
      return (h("owc-tooltip", Object.assign({ class: tooltipClasses, style: Object.assign({ maxWidth: `${width}px` }, tooltipStyle), placement: "top", offset: [0, TOOLTIP_OFFSET], disableTriggers: true, leaveDuration: 0, strategy: "fixed" }, tooltipProps, { visible: this.tooltipVisible && !!tooltipContent && !this.isVisible(), anchor: this.inputRef }), tooltipContent));
    }
  }
  getMenuElement(hostClasses) {
    const menuFooterClasses = this.component.getChildBemClasses('menu-footer', hostClasses);
    const _a = this.menuProps || {}, _b = _a.listProps, _c = _b === void 0 ? {} : _b, { onSelectedChange = undefined } = _c, listProps = __rest(_c, ["onSelectedChange"]), { onClickOutside } = _a, menuProps = __rest(_a, ["listProps", "onClickOutside"]);
    return (h("owc-menu", Object.assign({ limit: this.limit, position: "bottom", trigger: "manual", strategy: "fixed", autoCloseOnSelection: !this.multiple, anchorWidth: true }, menuProps, { listProps: Object.assign(Object.assign({ selected: this.activeSelections, multiple: this.multiple, showSelectorControl: this.multiple, onSelectedChange: ev => this.onListSelectedChanged(ev, onSelectedChange), ref: el => (this.listRef = el), hostRole: 'listbox' }, accessibilityAttributes({ required: this.required, readonly: !this.isSearchingNow() })), listProps), preventOverflow: true, anchor: this.inputRef, visible: this.isVisible(), onVisibleChange: e => (this.visible = e.detail), onClickOutside: e => this.onMenuClickOutside(e, onClickOutside) }), h("div", { ref: this.defaultSlotWrapper }, h("slot", null)), this.visible ? this.getMenuHeaderElement(hostClasses, listProps === null || listProps === void 0 ? void 0 : listProps.size) : null, this.visible && this.isSelectOnApplyVisible() ? (h("owc-component-footer", { slot: "footer", class: menuFooterClasses, onClick: stopEvent }, h("div", { slot: "suffix" }, h("owc-button", { onClick: this.onApplyButtonClicked }, this.applyButtonText || 'Apply')))) : null));
  }
  getMenuHeaderElement(hostClasses, listSize) {
    if (this.isSearchingNow()) {
      return this.searchTerm && this.allItemsHidden && this.notFoundText ? (h("owc-component-header", { slot: "header" }, h("owc-typography", null, this.notFoundText || 'Not Found'))) : null;
    }
    else {
      return this.multiple && !this.hideSelectAll ? (h("owc-list-item", { slot: "header", class: this.component.getChildBemClasses('menu-header', hostClasses), showSelectorControl: true, multiple: true, managedCheckboxState: true, size: listSize || 'medium', checkboxState: this.selectAllCheckboxState, onClick: this.onSelectAllClicked }, this.selectAllText || 'All')) : null;
    }
  }
  render() {
    const inputValue = this.getInputValue();
    const hostClasses = this.component.getHostBemClasses({
      'has-clear-button': !this.noClearIcon && !this.disabled && !!inputValue,
      editing: this.isSearchingNow(),
      disabled: this.disabled,
      loading: this.loading
    });
    return (h(Host, { class: hostClasses, onFocus: this.focusInputElement, tabIndex: this.disabled ? -1 : this.component.getInitialTabIndex() }, this.getInputElement(hostClasses, inputValue), this.getInputTooltipElement(hostClasses), this.getMenuElement(hostClasses)));
  }
  get hostElement() { return this; }
  static get watchers() { return {
    "value": ["onValueChanged"],
    "disabled": ["onDisabledChanged"],
    "visible": ["onVisibleChanged"],
    "validity": ["onValidityChange"],
    "multiple": ["onValidatorParamsChange"],
    "minlength": ["onValidatorParamsChange"],
    "maxlength": ["onValidatorParamsChange"],
    "required": ["onValidatorParamsChange"],
    "notSelectableValues": ["onValidatorParamsChange"],
    "selectableValues": ["onValidatorParamsChange"],
    "searchTerm": ["onSearchTermChanged"]
  }; }
  static get style() { return baseDropdownCss; }
}, [4, "owc-base-dropdown", {
    "visible": [1028],
    "validity": [16],
    "disabled": [4],
    "label": [1],
    "limit": [2],
    "name": [1],
    "placeholder": [1],
    "required": [4],
    "value": [16],
    "variant": [1],
    "size": [1],
    "rounded": [4],
    "searchable": [4],
    "editable": [4],
    "notFoundText": [1, "not-found-text"],
    "noClearIcon": [4, "no-clear-icon"],
    "disableValidation": [4, "disable-validation"],
    "multiple": [4],
    "selectAllText": [1, "select-all-text"],
    "hideSelectAll": [4, "hide-select-all"],
    "addItemOnMatch": [4, "add-item-on-match"],
    "showApplyButton": [4, "show-apply-button"],
    "applyButtonText": [1, "apply-button-text"],
    "minlength": [514],
    "maxlength": [514],
    "notSelectableValues": [16],
    "selectableValues": [16],
    "controlled": [516],
    "inputProps": [16],
    "clearButtonProps": [16],
    "menuTriggerButtonProps": [16],
    "menuProps": [16],
    "tooltipProps": [16],
    "selectAllCheckboxState": [32],
    "selectedItemTexts": [32],
    "searchTerm": [32],
    "allItemsHidden": [32],
    "activeSelections": [32],
    "tooltipVisible": [32],
    "isFocused": [32],
    "inputRef": [32],
    "listRef": [32],
    "loading": [32],
    "validate": [64]
  }]);
__decorate([
  DefaultSlotWrapper()
], BaseDropdown.prototype, "defaultSlotWrapper", void 0);
function defineCustomElement() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-base-dropdown", "owc-button", "owc-card", "owc-checkbox", "owc-component-footer", "owc-component-header", "owc-divider", "owc-icon", "owc-icon-button", "owc-input", "owc-list", "owc-list-item", "owc-menu", "owc-popover", "owc-radio", "owc-tooltip", "owc-typography"];
  components.forEach(tagName => { switch (tagName) {
    case "owc-base-dropdown":
      if (!customElements.get(tagName)) {
        customElements.define(tagName, BaseDropdown);
      }
      break;
    case "owc-button":
      if (!customElements.get(tagName)) {
        defineCustomElement$g();
      }
      break;
    case "owc-card":
      if (!customElements.get(tagName)) {
        defineCustomElement$f();
      }
      break;
    case "owc-checkbox":
      if (!customElements.get(tagName)) {
        defineCustomElement$e();
      }
      break;
    case "owc-component-footer":
      if (!customElements.get(tagName)) {
        defineCustomElement$d();
      }
      break;
    case "owc-component-header":
      if (!customElements.get(tagName)) {
        defineCustomElement$c();
      }
      break;
    case "owc-divider":
      if (!customElements.get(tagName)) {
        defineCustomElement$b();
      }
      break;
    case "owc-icon":
      if (!customElements.get(tagName)) {
        defineCustomElement$a();
      }
      break;
    case "owc-icon-button":
      if (!customElements.get(tagName)) {
        defineCustomElement$9();
      }
      break;
    case "owc-input":
      if (!customElements.get(tagName)) {
        defineCustomElement$8();
      }
      break;
    case "owc-list":
      if (!customElements.get(tagName)) {
        defineCustomElement$7();
      }
      break;
    case "owc-list-item":
      if (!customElements.get(tagName)) {
        defineCustomElement$6();
      }
      break;
    case "owc-menu":
      if (!customElements.get(tagName)) {
        defineCustomElement$5();
      }
      break;
    case "owc-popover":
      if (!customElements.get(tagName)) {
        defineCustomElement$4();
      }
      break;
    case "owc-radio":
      if (!customElements.get(tagName)) {
        defineCustomElement$3();
      }
      break;
    case "owc-tooltip":
      if (!customElements.get(tagName)) {
        defineCustomElement$2();
      }
      break;
    case "owc-typography":
      if (!customElements.get(tagName)) {
        defineCustomElement$1();
      }
      break;
  } });
}

export { BaseDropdown as B, defineCustomElement as d };
