import { proxyCustomElement, HTMLElement, createEvent, h, Host } from '@stencil/core/internal/client';
import { n as findElementInEventPath, m as stopImmediatePropagation, k as stopEvent } from './element.js';
import { b as accessibility } from './accessibility.js';
import { F as FocusType, H as HandlerKeyCombination, K as KeydownManager } from './MutationObserverSafe.js';
import { C as ComponentUtils } from './ComponentUtils.js';
import { C as ClickOutside } from './click-outside.js';
import { B as BehaviorSubject } from './subscription-list.class.js';

const radioGroupCss = ".owc-radio-group{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;outline:none;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.owc-radio-group.owc-radio-group--disabled{pointer-events:none}.owc-radio-group.owc-radio-group--direction-horizontal{display:grid;width:100%;grid-template-columns:1fr;grid-auto-columns:1fr;grid-auto-flow:column}";

var __decorate = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
const RADIO_ITEM = 'owc-radio';
const RadioGroup = /*@__PURE__*/ proxyCustomElement(class RadioGroup extends HTMLElement {
  constructor() {
    super();
    this.__registerHost();
    this.valueChange = createEvent(this, "valueChange", 3);
    this.component = new ComponentUtils(this);
    this.clickOutsideEnabled$ = new BehaviorSubject(true);
    this.setValue = (newValue) => {
      if (newValue !== this.value) {
        if (this.controlled) {
          this.valueChange.emit(newValue);
        }
        else {
          this.value = newValue;
        }
      }
    };
    this.onClosestFormReset = () => {
      this.setValue(this.defaultValue);
    };
    this.getChildrenItems = () => [...Array.from(this.hostElement.querySelectorAll(RADIO_ITEM))];
    this.getSelectableElements = () => this.disabled ? [] : this.getChildrenItems().filter(item => !item.disabled);
    this.onFocusHandler = () => this.component.keydownManager().setCurrentItem(this.getFirstFocusableItem());
    this.onSelectHandler = () => {
      var _a;
      this.setValue((_a = this.component.keydownManager().getCurrentItem()) === null || _a === void 0 ? void 0 : _a.value);
    };
    this.getFirstFocusableItem = () => {
      const selectableElements = this.getSelectableElements();
      return selectableElements.find(item => item.value === this.value) || selectableElements[0];
    };
    this.updateChildren = () => {
      this.getChildrenItems().forEach(el => {
        el.tabIndex = -1;
        el.controlled = this.controlled;
        el.checked = el.value === this.value;
        el.groupDisabled = this.disabled;
        el.nativeInputProps = Object.assign(Object.assign({}, el.nativeInputProps), { tabIndex: -1 });
      });
    };
    this.name = undefined;
    this.direction = 'vertical';
    this.controlled = false;
    this.value = undefined;
    this.disabled = false;
  }
  onValueChange(newValue, oldValue) {
    newValue !== oldValue && !this.controlled && this.valueChange.emit(newValue);
    this.updateChildren();
  }
  onRadioValueChange(event) {
    const radioElement = findElementInEventPath(event, RADIO_ITEM);
    if (radioElement) {
      stopImmediatePropagation(event);
      stopEvent(event);
      event.detail && this.setValue(radioElement.value);
      this.updateChildren();
    }
  }
  onListItemClick(event) {
    const item = findElementInEventPath(event, RADIO_ITEM);
    if (item && !item.disabled) {
      stopEvent(event);
      this.hostElement.focus();
      this.component.keydownManager().setCurrentItem(item, 'top', false);
    }
  }
  onClickOutside() {
    var _a;
    (_a = this.component) === null || _a === void 0 ? void 0 : _a.keydownManager().clearFocusClassFromAllItems();
  }
  componentDidLoad() {
    this.component
      .withKeydownManager({
      circular: true,
      focusType: FocusType.BY_CLASS,
      parentSelector: () => this.hostElement,
      selector: () => this.getSelectableElements(),
      offset: () => { var _a; return ((_a = this.hostElement) === null || _a === void 0 ? void 0 : _a.offsetTop) || 0; },
      onHostFocus: () => this.onFocusHandler(),
      onHostBlur: () => this.component.keydownManager().clearFocusClassFromAllItems(),
      handlers: [
        {
          key: [HandlerKeyCombination.ArrowDown, HandlerKeyCombination.ArrowRight],
          handler: KeydownManager.PredefinedHandler.NEXT,
          scrollBehavior: 'top',
          disabled: () => this.disabled
        },
        {
          key: [HandlerKeyCombination.ArrowUp, HandlerKeyCombination.ArrowLeft],
          handler: KeydownManager.PredefinedHandler.PREVIOUS,
          scrollBehavior: 'bottom',
          disabled: () => this.disabled
        },
        {
          key: [HandlerKeyCombination.Space, HandlerKeyCombination.Enter],
          handler: () => this.onSelectHandler(),
          disabled: () => this.disabled
        }
      ]
    })
      .keydownManager()
      .clearFocusClassFromAllItems();
    this.defaultValue = this.value;
  }
  componentWillRender() {
    this.updateChildren();
    const form = this.hostElement.closest('form');
    form === null || form === void 0 ? void 0 : form.removeEventListener('reset', this.onClosestFormReset);
    form === null || form === void 0 ? void 0 : form.addEventListener('reset', this.onClosestFormReset);
  }
  render() {
    const hostClasses = this.component.getHostBemClasses({
      disabled: this.disabled,
      [`direction-${this.direction || 'vertical'}`]: true
    });
    return (h(Host, Object.assign({ tabIndex: this.disabled ? -1 : this.component.getInitialTabIndex(0), class: hostClasses }, accessibility({ role: 'radiogroup', aria: { disabled: !!this.disabled } })), h("slot", null)));
  }
  get hostElement() { return this; }
  static get watchers() { return {
    "value": ["onValueChange"]
  }; }
  static get style() { return radioGroupCss; }
}, [4, "owc-radio-group", {
    "name": [513],
    "direction": [513],
    "controlled": [516],
    "value": [1537],
    "disabled": [516]
  }, [[0, "valueChange", "onRadioValueChange"], [0, "click", "onListItemClick"]]]);
__decorate([
  ClickOutside({ enabled$: self => self.clickOutsideEnabled$ })
], RadioGroup.prototype, "onClickOutside", null);
function defineCustomElement() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-radio-group"];
  components.forEach(tagName => { switch (tagName) {
    case "owc-radio-group":
      if (!customElements.get(tagName)) {
        customElements.define(tagName, RadioGroup);
      }
      break;
  } });
}

export { RadioGroup as R, defineCustomElement as d };
