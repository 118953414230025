import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { snackbarService } from '@one/web-components';

interface StatusCode {
  code: string;
  status: string;
  message: string;
  response: any;
}
@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  public constructor(private http: HttpClient) {}
  public env = new BehaviorSubject<string>('');
  public licenseUrl = '/ss/admin/license/api/v1';
  public get(url: string): any {
    return this.http.get(`${url}`);
  }
  public delete(url: string): any {
    return this.http.delete(`${url}`);
  }
  public post(url: string, params: any): any {
    return this.http.post(`${url}`, params);
  }

  private getMessageFromResponse(response: StatusCode): string {
    let message = '';
    if (response?.code) {
      message = this.getMessageFromCodeResponse(response);
    } else {
      message = this.getDefaultMessage(response);
    }
    return message;
  }

  private getMessageFromCodeResponse(response: StatusCode): string {
    let message = '';
    message = response?.message;
    if (message === '') {
      message = this.getDefaultMessage(response);
    }
    return message;
  }

  public showNotification(msg: string, msgType: any): void {
    const element = snackbarService.show({
      message: msg, // can be an HTML markup
      //actions: [{ content: 'Action', buttonProps: { variant: 'tertiary' } }],
      snackbarProps: {
        variant: 'primary',
        status: msgType,
        direction: 'horizontal',
        showClose: true,
        elevated: false,
        duration: 0,
        verticalPlacement: 'top',
        horizontalPlacement: 'right',
        onOpen: () => console.log('Snackbar is appeared in the screen'),
        onClose: () => console.log('Snackbar is disappeared in the screen'),
      },
    });
  }

  private getDefaultMessage(response: StatusCode): string {
    return response?.message ? response?.message : 'Something went wrong';
  }

  public notify(
    response: StatusCode,
    msgType: string,
    showAlert: boolean = true,
  ): any {
    const message = this.getMessageFromResponse(response);
    if (showAlert) {
      this.showNotification(message, msgType);
    }
    return message;
  }
}
