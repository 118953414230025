import { proxyCustomElement, HTMLElement, createEvent, h, Host } from '@stencil/core/internal/client';
import { n as findElementInEventPath, b as generateElementId, g as getDocument, d as isElementReady } from './element.js';
import { a as accessibilityAttributes, b as accessibility } from './accessibility.js';
import { F as FocusType, H as HandlerKeyCombination, K as KeydownManager } from './MutationObserverSafe.js';
import { u as useRef } from './ref.js';
import { a as DefaultSlotWrapper, C as ComponentUtils } from './ComponentUtils.js';
import { a as Context, C as ContextValue } from './context.js';
import { C as CTX_KEYS } from './context-keys.const.js';
import { d as defineCustomElement$4 } from './card.js';
import { d as defineCustomElement$3 } from './icon.js';
import { d as defineCustomElement$2 } from './list.js';
import { d as defineCustomElement$1 } from './popover.js';

const menuCss = ".owc-menu{max-width:17.5rem;min-width:6.875rem;z-index:var(--one-z-index-popover);background-color:var(--one-generic-background-transparent);border-radius:0.25rem;display:contents;outline:none}.owc-menu.owc-menu--loading{visibility:hidden}.owc-menu.owc-menu--loading owc-list-item{visibility:hidden}.owc-menu owc-popover{min-width:inherit;max-width:inherit;width:inherit;-webkit-box-shadow:inherit;box-shadow:inherit;z-index:inherit;background-color:inherit;border-radius:inherit;border:inherit}.owc-menu owc-popover owc-card{-webkit-box-shadow:var(--one-elevation-box-shadow-8);box-shadow:var(--one-elevation-box-shadow-8)}";

var __decorate = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __rest = (undefined && undefined.__rest) || function (s, e) {
  var t = {};
  for (var p in s)
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
      t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function")
    for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
        t[p[i]] = s[p[i]];
    }
  return t;
};
const MENU_ITEM_LIMIT = 6;
const LIST_ITEM_SELECTOR = 'owc-list-item';
const TIME_INTERVAL = 20;
const NUMBER_OF_ATTEMPTS = 100;
const HOST_BLUR_ACTION_TIMEOUT = 500;
const Menu = /*@__PURE__*/ proxyCustomElement(class Menu extends HTMLElement {
  constructor() {
    super();
    this.__registerHost();
    this.visibleChange = createEvent(this, "visibleChange", 3);
    this.clickOutside = createEvent(this, "clickOutside", 3);
    this.listElement = useRef();
    this.ctxMenuStatus = new ContextValue();
    this.clickedCard = false;
    this.popoverElement = useRef();
    this.component = new ComponentUtils(this);
    this.setCtxStatus = (s) => this.ctxMenuStatus.set(Object.assign(Object.assign({}, this.ctxMenuStatus.get()), s));
    this.onListClick = (ev, originalHandler) => {
      var _a, _b;
      originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler(ev);
      if (!ev.defaultPrevented) {
        const item = findElementInEventPath(ev, LIST_ITEM_SELECTOR);
        if (item && !item.notSelectable) {
          this.component.keydownManager().setCurrentItem(item);
          (_b = (_a = this.getAnchorElement()) === null || _a === void 0 ? void 0 : _a.focus) === null || _b === void 0 ? void 0 : _b.call(_a);
          this.autoCloseOnSelection && (this.visible = false);
        }
      }
    };
    this.hostId = generateElementId(this.hostElement);
    this.anchor = undefined;
    this.position = 'bottom-start';
    this.limit = MENU_ITEM_LIMIT;
    this.visible = false;
    this.notFocusable = false;
    this.preventVisibilityChangeOnAnchorBlur = false;
    this.autoCloseOnSelection = false;
    this.strategy = 'absolute';
    this.trigger = 'click';
    this.offset = undefined;
    this.listProps = undefined;
    this.cardProps = undefined;
    this.anchorWidth = false;
    this.preventOverflow = false;
    this.focusedElementId = '';
    this.loading = true;
  }
  onLimitChange() {
    this.reflectLimitChanges();
  }
  visibleWatcher(newValue, oldValue) {
    var _a, _b, _c;
    !!newValue !== !!oldValue && this.visibleChange.emit(!!newValue);
    if (this.visible) {
      this.clickedCard = false;
      this.reflectLimitChanges();
      this.updateWidth();
      (_b = (_a = this.hostElement).focus) === null || _b === void 0 ? void 0 : _b.call(_a, { preventScroll: true });
      const selectedIndex = this.getSelectableListItems().findIndex(el => el.selected);
      (_c = this.component.keydownManager()) === null || _c === void 0 ? void 0 : _c.setCurrentIndex(selectedIndex).clearFocusClassFromAllItems();
    }
    this.setCtxStatus({ visible: this.visible });
  }
  onAnchorChanged() {
    this.updateWidth();
    this.component.withKeydownManager({
      selector: () => this.getSelectableListItems(),
      parentSelector: () => this.listElement.current,
      circular: true,
      focusType: FocusType.BY_CLASS,
      onHostFocus: () => {
        clearTimeout(this.blurTimer);
        this.clickedCard = false;
      },
      onHostBlur: () => {
        clearTimeout(this.blurTimer);
        this.blurTimer = setTimeout(() => {
          !this.clickedCard && !this.preventVisibilityChangeOnAnchorBlur && this.trigger !== 'manual' && (this.visible = false);
        }, HOST_BLUR_ACTION_TIMEOUT);
      },
      onCurrentItemChange: ({ items, current }) => {
        var _a;
        this.focusedElementId = ((_a = items[current]) === null || _a === void 0 ? void 0 : _a.id) || undefined;
      },
      handlers: [
        {
          key: [HandlerKeyCombination.ArrowDown],
          handler: KeydownManager.PredefinedHandler.NEXT,
          disabled: () => !this.visible,
          scrollBehavior: 'bottom'
        },
        {
          key: [HandlerKeyCombination.ArrowUp],
          handler: KeydownManager.PredefinedHandler.PREVIOUS,
          disabled: () => !this.visible,
          scrollBehavior: 'top'
        },
        {
          key: [HandlerKeyCombination.Enter],
          handler: KeydownManager.PredefinedHandler.SELECT,
          disabled: () => !this.visible
        },
        {
          key: [HandlerKeyCombination.Escape],
          handler: () => this.visible && (this.visible = false)
        }
      ]
    }, this.getAnchorElement());
    this.visible && this.component.keydownManager().clearFocusClassFromAllItems();
  }
  getAnchorElement() {
    var _a;
    return typeof this.anchor === 'string' ? (_a = getDocument()) === null || _a === void 0 ? void 0 : _a.getElementById(this.anchor) : this.anchor;
  }
  getAnchorElementId() {
    const anchor = this.getAnchorElement();
    if (anchor) {
      !anchor.id && (anchor.id = generateElementId(anchor));
      return anchor.id;
    }
  }
  getListItems(src) {
    return Array.from((src || this.hostElement).querySelectorAll(LIST_ITEM_SELECTOR));
  }
  getSelectableListItems() {
    return this.getListItems(this.listElement.current).filter(item => !item.notSelectable);
  }
  getLiElements() {
    var _a;
    return Array.from(((_a = this.listElement.current) === null || _a === void 0 ? void 0 : _a.querySelectorAll(`${LIST_ITEM_SELECTOR} > .owc-list-item__list-item-container`)) || []);
  }
  reflectLimitChanges() {
    if (this.visible) {
      isElementReady(() => this.getLiElements().some(item => item.offsetHeight > 0), NUMBER_OF_ATTEMPTS, TIME_INTERVAL).then(() => {
        var _a;
        const childrenNodes = this.getLiElements();
        const totalHeight = childrenNodes.slice(0, this.limit).reduce((sum, item) => sum + (item.offsetHeight || 0), 0);
        const itemHeight = childrenNodes.find(listItem => listItem.offsetHeight > 0);
        const maxHeight = Math.max(totalHeight, (itemHeight === null || itemHeight === void 0 ? void 0 : itemHeight.offsetHeight) * this.limit);
        maxHeight && ((_a = this.listElement.current) === null || _a === void 0 ? void 0 : _a.style.setProperty('max-height', `${maxHeight}px`));
        this.loading = false;
      });
    }
  }
  updateAriaAttributes() {
    const anchor = this.getAnchorElement();
    const list = this.listElement.current;
    if (anchor) {
      Object.entries(accessibilityAttributes({ haspopup: true, controls: list === null || list === void 0 ? void 0 : list.id, expanded: this.visible })).forEach(([key, value]) => anchor.setAttribute(key, value));
    }
    if (list) {
      Object.entries(accessibilityAttributes({
        activedescendant: this.visible ? this.focusedElementId : undefined,
        labelledby: this.getAnchorElementId()
      })).forEach(([key, value]) => list.setAttribute(key, value));
    }
  }
  updateWidth() {
    var _a, _b, _c, _d, _e;
    if (this.visible && this.anchorWidth) {
      const anchor = this.getAnchorElement();
      const width = ((_a = anchor.getBoundingClientRect()) === null || _a === void 0 ? void 0 : _a.width) || ((_b = this.hostElement.getBoundingClientRect()) === null || _b === void 0 ? void 0 : _b.width) || 0;
      (_c = this.hostElement.style) === null || _c === void 0 ? void 0 : _c.setProperty('width', `${width}px`);
      (_d = this.hostElement.style) === null || _d === void 0 ? void 0 : _d.setProperty('min-width', `${width}px`);
      (_e = this.hostElement.style) === null || _e === void 0 ? void 0 : _e.setProperty('max-width', `${width}px`);
    }
  }
  componentDidLoad() {
    this.setCtxStatus({ visible: this.visible });
    this.onAnchorChanged();
    this.reflectLimitChanges();
  }
  componentWillRender() {
    this.updateAriaAttributes();
  }
  render() {
    const hostClasses = this.component.getHostBemClasses({ loading: this.loading });
    const _a = this.listProps || {}, { ref: listRef, onclick: originalListClick } = _a, listProps = __rest(_a, ["ref", "onclick"]);
    return (h(Host, Object.assign({ class: hostClasses, tabIndex: !this.notFocusable && this.visible ? this.component.getInitialTabIndex(0) : -1 }, accessibility({ role: 'region', aria: { labelledby: this.getAnchorElementId() } })), h("owc-popover", { ref: this.popoverElement, anchor: this.anchor, visible: this.visible, strategy: this.strategy, offset: this.offset, tabIndex: -1, position: this.position, trigger: this.trigger, disableAccessibility: true, preventOverflow: this.preventOverflow, onVisibleChange: e => (this.visible = e.detail), onClickOutside: () => this.clickOutside.emit() }, h("owc-card", Object.assign({}, this.cardProps, { onMouseDown: () => (this.clickedCard = true) }), h("slot", { name: "header" }), h("owc-list", Object.assign({ tabIndex: -1, hostRole: "menu", notFocusable: true }, listProps, { ref: el => this.listElement(el, [listRef]), onClick: e => this.onListClick(e, originalListClick) }), h("slot", null)), h("slot", { name: "footer" })))));
  }
  get hostElement() { return this; }
  static get watchers() { return {
    "limit": ["onLimitChange"],
    "visible": ["visibleWatcher"],
    "anchor": ["onAnchorChanged"]
  }; }
  static get style() { return menuCss; }
}, [4, "owc-menu", {
    "hostId": [1537, "id"],
    "anchor": [1],
    "position": [513],
    "limit": [514],
    "visible": [1540],
    "notFocusable": [516, "not-focusable"],
    "preventVisibilityChangeOnAnchorBlur": [4, "prevent-visibility-change-on-anchor-blur"],
    "autoCloseOnSelection": [4, "auto-close-on-selection"],
    "strategy": [513],
    "trigger": [513],
    "offset": [16],
    "listProps": [16],
    "cardProps": [16],
    "anchorWidth": [4, "anchor-width"],
    "preventOverflow": [4, "prevent-overflow"],
    "focusedElementId": [32],
    "loading": [32]
  }]);
__decorate([
  DefaultSlotWrapper()
], Menu.prototype, "listElement", void 0);
__decorate([
  Context(CTX_KEYS.MENU.STATUS)
], Menu.prototype, "ctxMenuStatus", void 0);
function defineCustomElement() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-menu", "owc-card", "owc-icon", "owc-list", "owc-popover"];
  components.forEach(tagName => { switch (tagName) {
    case "owc-menu":
      if (!customElements.get(tagName)) {
        customElements.define(tagName, Menu);
      }
      break;
    case "owc-card":
      if (!customElements.get(tagName)) {
        defineCustomElement$4();
      }
      break;
    case "owc-icon":
      if (!customElements.get(tagName)) {
        defineCustomElement$3();
      }
      break;
    case "owc-list":
      if (!customElements.get(tagName)) {
        defineCustomElement$2();
      }
      break;
    case "owc-popover":
      if (!customElements.get(tagName)) {
        defineCustomElement$1();
      }
      break;
  } });
}

export { Menu as M, defineCustomElement as d };
