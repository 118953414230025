<owc-modal-dialog #modalDialogElement [(ngModel)]="visible" [cardProps]="{ elevated: true }">
<owc-progress-bar #progressBarElement  [value]="50" *ngIf="loading" style="width: 100%;padding-bottom: 10px;" />
  <div class="flex-row">
    <div class="col-12 mb-24 " style="display: flex;">
      <div class="col-2"></div>
      <div class="col-8">
        <div [style]="{ display: 'flex', padding: '0 24px' }">
          <div class="col-12">
            <owc-editable-dropdown style="width: 100%;"
              #editableDropdownElement id="countryDropdown"
              [(ngModel)]="selectedCountry"
              [label]="'Select country'"
              [limit]="10"
              [disabled]="this.data.countryCode || delayCountry"
              required appStopAutofocus
              (valueChange)="onCountryChange($event)"
            >
              <ng-container *ngFor="let c of countries">
                <owc-list-item value="{{ c.value }}">{{
                  c.name
                }}</owc-list-item>
              </ng-container>
            </owc-editable-dropdown>
          </div>
        </div>
      </div>
      <div class="col-2"></div>
  </div>
  <div class="col-12 mb-24 " style="display: flex;">
      <div class="col-2"></div>
      <div class="col-8">
        <div [style]="{ display: 'flex', padding: '0 24px' }">
          <div class="col-12">
            <owc-editable-dropdown style="width: 100%;"
              #editableDropdownElement id="laboratoryDropdown"
              [(ngModel)]="selectedLaboratory"
              [label]="'Select laboratory'"
              [limit]="10"
               required appStopAutofocus
               [disabled]="this.data.countryCode || delayCountry"
              (valueChange)="onLaboratoryChange($event)"
            >
              <ng-container *ngFor="let lab of laboratories">
                <owc-list-item value="{{ lab.id }}">{{
                  lab.name
                }}</owc-list-item>
              </ng-container>
            </owc-editable-dropdown>
          </div>
        </div>
      </div>
      <div class="col-2"></div>
    </div>
    <div class="col-12 mb-24 " style="display: flex;">
        <div class="col-2"></div>
        <div class="col-8">
            <div [style]="{ display: 'flex', padding: '0 24px' }">
              <div class="col-12">
                <owc-editable-dropdown style="width: 100%;"
                  #editableDropdownElement id="licenseDropdown"
                  [(ngModel)]="selectedLicense"
                  [label]="'Select License'"
                  [limit]="10"
                  [disabled]="this.data.countryCode || delayCountry"
                  required appStopAutofocus
                  (valueChange)="onLicenseChange($event)"
                >
                  <ng-container *ngFor="let license of licenseList">
                    <owc-list-item value="{{ license }}">{{ license }}</owc-list-item>
                  </ng-container>
                </owc-editable-dropdown>
              </div>
            </div>
        </div>
        <div class="col-2"></div>
    </div>
    <div class="col-12 mb-24 " style="display: flex;">
          <div class="col-2"></div>
          <div class="col-8" style=" margin-left: 30px;">
            <div class="col-12" >
                <label for="name" class="col-3"> License type</label>
                <owc-radio-group id="LicenseType" class="col-9" #radioGroupElement [direction]="direction" [(ngModel)]="licenseType"  class="w-100"  (valueChange)="onLicenseTypeChange(licenseType)">
                   <span > <owc-radio value="trial">Trial</owc-radio></span>
                   <span style="margin-left: -80px;"><owc-radio value="paid">Paid</owc-radio> </span>
                </owc-radio-group>
            </div> 
          </div>
          <div class="col-2"></div>
    </div>
        <!-- celender to select expiry date-->
    <div class="col-12 mb-24 " style="display: flex;" >
        <div class="col-2"></div>
        <div class="col-8">
          <div style=" padding: 0px 24px;" id="admin-date-picker">
            <owc-datepicker id="date-picker" [disabledDates]="[]" controlled  #datepickerElement  auto-close
             outlined 
            (valueChange)="onDateValueChange($event)" [noClearIcon]="true" [(ngModel)]=" this.expiryDate" 
            compact format="dd MMM yyyy" [variant]="'outlined'" [label]="'Select license expiry date'" 
            [placeholder]="datePickerPlaceholder" [type]="datePickerType"> </owc-datepicker>
          </div> 
        </div>
        <div class="col-2"></div>
     </div>  
  </div>

  <owc-component-header slot="header">Assign License</owc-component-header>

  <owc-component-footer slot="footer" style="display: block">
    <div slot="actions"  [style]="{ display: 'flex', justifyContent: 'flex-end' }" >
      <owc-button id="cancelBtn"  variant="secondary" [style]="{ 'margin-left': '12px' }"   flat (click)="closePopup()"
        id="close-btn" >Cancel</owc-button>

      <owc-button id="assignBtn" [style]="{ 'margin-left': '12px' }" flat id="submit-btn"  (click)="addEditLicense()"> Assign </owc-button>
    </div>
  </owc-component-footer>
</owc-modal-dialog>
