import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OneWebComponentsAngularModule } from '@one/angular';

import { RouterModule } from '@angular/router';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './token.interceptor';

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminComponent } from './components/admin/admin.component';
import { AddModalComponent } from './components/common/add-modal/add-modal.component';
import { ConfirmModalComponent } from './components/common/confirm-modal/confirm-modal.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

@NgModule({
  declarations: [AdminComponent, AddModalComponent, ConfirmModalComponent, NotFoundComponent],
  exports: [],
  imports: [
    HttpClientModule,
    RouterModule,
    CommonModule,
    OneWebComponentsAngularModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
})
export class SampleFeatureModule {}
