import { Component, OnInit } from '@angular/core';
import $ from 'jquery';
@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss'
})

export class NotFoundComponent implements OnInit{
  constructor() {
  
  }
  public ngOnInit() {
    $('#tabsElement').hide()
  }

}


