import {
  Component,
  ViewChild,
  Renderer2,
  Inject,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Components } from '@one/web-components';
import { OnInit } from '@angular/core';
import { FeatureService } from '../../../../services/feature.service'; // Import the FeatureService class
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrl: './confirm-modal.component.scss'
})
export class ConfirmModalComponent implements OnInit, AfterViewInit {
  @ViewChild('modalDialogElement', { static: true }) modalDialogElement!: Components.OwcModalDialog;
 
  @ViewChild('progressBarElement', { static: true }) progressBarElement!: Components.OwcProgressBar;


  public subscription: Subscription[] = [];

  loading = false;
  visible: boolean = false;

  
  constructor(public datepipe: DatePipe, private renderer: Renderer2, public dialog: MatDialog,private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,  private featureService: FeatureService, private route: ActivatedRoute) {
    this.visible = true;
  }

  public ngOnInit(): void {
    console.log('ConfirmModalComponent ngOnInit');
    console.log('data', this.data);
  }

  ngAfterViewInit(): void {
  }

  public deleteLicense(): void {
    let url = `${this.featureService.licenseUrl}/laboratories/`;
    url =url + `${this.data?.countryCode}/${this.data?.labId}/?license_entitlement_ids=${this.data?.license_entitlement_id}`;
    this.subscription.push(
      this.featureService.delete(url).subscribe(
        (response: any) => {
          if (response?.status === 'SUCCESS') {
            console.log('Delete success :: ', response);
            this.featureService.notify(response, 'positive');
            setTimeout(() => {
              window.location.reload();
            },1000);
          } else {
            // code to show message box
            this.featureService.notify(response, 'alert');
          }
        },
        (error: any) => {
          console.log('delete License error: ' + error);
          this.featureService.notify(
            error?.error ? error.error : error,
            'alert',
          );
        },
      ),
    );
  }

  public closePopup(): void {
    this.dialog.closeAll();
    this.visible = false;
  }
  
}
