import { proxyCustomElement, HTMLElement, createEvent, forceUpdate, h, Host } from '@stencil/core/internal/client';
import { i as isChildMutated } from './mutation-record-filters.js';
import { i as isSameArray } from './array.js';
import { t as isDefined } from './element.js';
import { b as accessibility } from './accessibility.js';
import './MutationObserverSafe.js';
import { u as useRef } from './ref.js';
import { a as DefaultSlotWrapper, C as ComponentUtils } from './ComponentUtils.js';
import { O as OnElementMutation } from './element-mutation.js';
import { d as defineCustomElement$h } from './base-dropdown.js';
import { d as defineCustomElement$g } from './button.js';
import { d as defineCustomElement$f } from './card.js';
import { d as defineCustomElement$e } from './checkbox.js';
import { d as defineCustomElement$d } from './component-footer.js';
import { d as defineCustomElement$c } from './component-header.js';
import { d as defineCustomElement$b } from './divider.js';
import { d as defineCustomElement$a } from './icon.js';
import { d as defineCustomElement$9 } from './icon-button.js';
import { d as defineCustomElement$8 } from './input.js';
import { d as defineCustomElement$7 } from './list.js';
import { d as defineCustomElement$6 } from './list-item.js';
import { d as defineCustomElement$5 } from './menu.js';
import { d as defineCustomElement$4 } from './popover.js';
import { d as defineCustomElement$3 } from './radio.js';
import { d as defineCustomElement$2 } from './tooltip.js';
import { d as defineCustomElement$1 } from './typography.js';

const selectDropdownCss = ".owc-select-dropdown{display:-ms-inline-flexbox;display:inline-flex;width:15.625rem}.owc-select-dropdown.owc-select-dropdown--loading owc-list-item{visibility:hidden}";

var __decorate = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
const MENU_ITEM_LIMIT = 6;
const SelectDropdown = /*@__PURE__*/ proxyCustomElement(class SelectDropdown extends HTMLElement {
  constructor() {
    super();
    this.__registerHost();
    this.valueChange = createEvent(this, "valueChange", 3);
    this.visibleChange = createEvent(this, "visibleChange", 3);
    this.clickOutside = createEvent(this, "clickOutside", 3);
    this.clearClick = createEvent(this, "clearClick", 3);
    this.validityChange = createEvent(this, "validityChange", 3);
    this.defaultSlotWrapper = useRef();
    this.baseDropdownRef = useRef();
    this.component = new ComponentUtils(this, { patchFocusMethod: () => this.focusDropdown() });
    this.focusDropdown = () => { var _a; return !this.disabled && !this.isFocused && ((_a = this.baseDropdownRef.current) === null || _a === void 0 ? void 0 : _a.focus()); };
    this.visible = false;
    this.validity = { state: 'valid' };
    this.disabled = false;
    this.label = undefined;
    this.limit = MENU_ITEM_LIMIT;
    this.name = undefined;
    this.placeholder = undefined;
    this.required = false;
    this.value = [];
    this.variant = 'filled';
    this.size = 'medium';
    this.rounded = false;
    this.searchable = false;
    this.notFoundText = 'Not Found';
    this.noClearIcon = false;
    this.multiple = false;
    this.selectAllText = 'All';
    this.hideSelectAll = false;
    this.showApplyButton = false;
    this.minlength = undefined;
    this.maxlength = undefined;
    this.controlled = false;
    this.notSelectableValues = [];
    this.disableValidation = false;
    this.selectableValues = [];
    this.applyButtonText = 'Apply';
    this.inputProps = undefined;
    this.menuProps = undefined;
    this.tooltipProps = undefined;
    this.clearButtonProps = undefined;
    this.menuTriggerButtonProps = undefined;
    this.isFocused = false;
    this.loading = true;
    this.internalValue = [];
  }
  onValueChanged() {
    let newInternalValue = [];
    Array.isArray(this.value) && (newInternalValue = this.value);
    typeof this.value === 'string' && (newInternalValue = this.value.split(',').map(v => v.trim()));
    newInternalValue = newInternalValue.filter(isDefined);
    !isSameArray(this.internalValue, newInternalValue) && (this.internalValue = newInternalValue);
  }
  onContentChange(records) {
    isChildMutated(records, this.hostElement) && forceUpdate(this.hostElement); // needs to trigger default slot relocation for dynamic list changes
  }
  /** Triggers the validation */
  validate() {
    var _a, _b;
    return Promise.resolve((_b = (_a = this === null || this === void 0 ? void 0 : this.baseDropdownRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.validate());
  }
  componentDidLoad() {
    this.loading = false;
  }
  componentWillLoad() {
    this.onValueChanged();
  }
  render() {
    const hostClasses = this.component.getHostBemClasses({ disabled: this.disabled, loading: this.loading });
    return (h(Host, Object.assign({ class: hostClasses, onFocus: this.focusDropdown, tabIndex: this.disabled ? -1 : this.component.getInitialTabIndex() }, accessibility({ role: 'region', aria: { label: this.label } })), h("owc-base-dropdown", { ref: this.baseDropdownRef, visible: this.visible, validity: this.validity, disableValidation: this.disableValidation, disabled: this.disabled, label: this.label, limit: this.limit, name: this.name, placeholder: this.placeholder, required: this.required, value: this.internalValue, controlled: this.controlled, variant: this.variant, size: this.size, rounded: this.rounded, searchable: this.searchable, notFoundText: this.notFoundText, noClearIcon: this.noClearIcon, multiple: this.multiple, selectAllText: this.selectAllText, hideSelectAll: this.hideSelectAll, showApplyButton: this.showApplyButton, applyButtonText: this.applyButtonText, minlength: this.minlength, maxlength: this.maxlength, selectableValues: this.selectableValues, notSelectableValues: this.notSelectableValues, inputProps: this.inputProps, menuProps: this.menuProps, tooltipProps: this.tooltipProps, clearButtonProps: this.clearButtonProps, menuTriggerButtonProps: this.menuTriggerButtonProps, onValueChange: e => {
        if (!isSameArray(this.internalValue, e.detail)) {
          !this.controlled && (this.value = e.detail);
          this.valueChange.emit(e.detail);
        }
      }, onVisibleChange: e => this.visibleChange.emit(e.detail), onClickOutside: () => this.clickOutside.emit(), onValidityChange: e => this.validityChange.emit(e.detail), onClearClick: () => this.clearClick.emit(), onFocus: () => {
        this.isFocused = true;
        this.hostElement.dispatchEvent(new KeyboardEvent('focus'));
      }, onBlur: () => {
        this.isFocused = false;
        this.hostElement.dispatchEvent(new KeyboardEvent('blur'));
      } }, h("slot", { name: "prefix" }), h("slot", { name: "suffix" }), h("slot", { name: "assistive-text" }), h("div", { ref: this.defaultSlotWrapper }, h("slot", null)))));
  }
  get hostElement() { return this; }
  static get watchers() { return {
    "value": ["onValueChanged"]
  }; }
  static get style() { return selectDropdownCss; }
}, [4, "owc-select-dropdown", {
    "visible": [1540],
    "validity": [16],
    "disabled": [516],
    "label": [513],
    "limit": [514],
    "name": [513],
    "placeholder": [513],
    "required": [516],
    "value": [1],
    "variant": [513],
    "size": [513],
    "rounded": [516],
    "searchable": [516],
    "notFoundText": [513, "not-found-text"],
    "noClearIcon": [516, "no-clear-icon"],
    "multiple": [516],
    "selectAllText": [513, "select-all-text"],
    "hideSelectAll": [4, "hide-select-all"],
    "showApplyButton": [516, "show-apply-button"],
    "minlength": [514],
    "maxlength": [514],
    "controlled": [516],
    "notSelectableValues": [16],
    "disableValidation": [4, "disable-validation"],
    "selectableValues": [16],
    "applyButtonText": [513, "apply-button-text"],
    "inputProps": [16],
    "menuProps": [16],
    "tooltipProps": [16],
    "clearButtonProps": [16],
    "menuTriggerButtonProps": [16],
    "isFocused": [32],
    "loading": [32],
    "internalValue": [32],
    "validate": [64]
  }]);
__decorate([
  DefaultSlotWrapper()
], SelectDropdown.prototype, "defaultSlotWrapper", void 0);
__decorate([
  OnElementMutation({
    target: self => self.defaultSlotWrapper.current,
    attributes: true,
    childList: true
  })
], SelectDropdown.prototype, "onContentChange", null);
function defineCustomElement() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-select-dropdown", "owc-base-dropdown", "owc-button", "owc-card", "owc-checkbox", "owc-component-footer", "owc-component-header", "owc-divider", "owc-icon", "owc-icon-button", "owc-input", "owc-list", "owc-list-item", "owc-menu", "owc-popover", "owc-radio", "owc-tooltip", "owc-typography"];
  components.forEach(tagName => { switch (tagName) {
    case "owc-select-dropdown":
      if (!customElements.get(tagName)) {
        customElements.define(tagName, SelectDropdown);
      }
      break;
    case "owc-base-dropdown":
      if (!customElements.get(tagName)) {
        defineCustomElement$h();
      }
      break;
    case "owc-button":
      if (!customElements.get(tagName)) {
        defineCustomElement$g();
      }
      break;
    case "owc-card":
      if (!customElements.get(tagName)) {
        defineCustomElement$f();
      }
      break;
    case "owc-checkbox":
      if (!customElements.get(tagName)) {
        defineCustomElement$e();
      }
      break;
    case "owc-component-footer":
      if (!customElements.get(tagName)) {
        defineCustomElement$d();
      }
      break;
    case "owc-component-header":
      if (!customElements.get(tagName)) {
        defineCustomElement$c();
      }
      break;
    case "owc-divider":
      if (!customElements.get(tagName)) {
        defineCustomElement$b();
      }
      break;
    case "owc-icon":
      if (!customElements.get(tagName)) {
        defineCustomElement$a();
      }
      break;
    case "owc-icon-button":
      if (!customElements.get(tagName)) {
        defineCustomElement$9();
      }
      break;
    case "owc-input":
      if (!customElements.get(tagName)) {
        defineCustomElement$8();
      }
      break;
    case "owc-list":
      if (!customElements.get(tagName)) {
        defineCustomElement$7();
      }
      break;
    case "owc-list-item":
      if (!customElements.get(tagName)) {
        defineCustomElement$6();
      }
      break;
    case "owc-menu":
      if (!customElements.get(tagName)) {
        defineCustomElement$5();
      }
      break;
    case "owc-popover":
      if (!customElements.get(tagName)) {
        defineCustomElement$4();
      }
      break;
    case "owc-radio":
      if (!customElements.get(tagName)) {
        defineCustomElement$3();
      }
      break;
    case "owc-tooltip":
      if (!customElements.get(tagName)) {
        defineCustomElement$2();
      }
      break;
    case "owc-typography":
      if (!customElements.get(tagName)) {
        defineCustomElement$1();
      }
      break;
  } });
}

export { SelectDropdown as S, defineCustomElement as d };
