import { proxyCustomElement, HTMLElement, createEvent, h, Host } from '@stencil/core/internal/client';
import { k as stopEvent, n as findElementInEventPath, j as getSiblings } from './element.js';
import { i as isSameArray, a as arrayIncludes } from './array.js';
import { b as accessibility } from './accessibility.js';
import './MutationObserverSafe.js';
import { C as ComponentUtils } from './ComponentUtils.js';

const expandableGroupCss = ".owc-expandable-group{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;width:100%;border:0.0625rem solid var(--one-color-background-contrast);border-radius:0.25rem}.owc-expandable-group owc-expandable{border-radius:0;border:none;border-bottom:0.0625rem solid var(--one-color-background-contrast);-webkit-box-shadow:none;box-shadow:none}.owc-expandable-group owc-expandable.owc-expandable--variant-transparent{border-bottom:none}.owc-expandable-group owc-expandable.owc-expandable--variant-filled>.owc-expandable__control-wrapper:hover{-webkit-box-shadow:none;box-shadow:none}.owc-expandable-group owc-expandable.owc-expandable--variant-filled.owc-expandable--expanded>.owc-expandable__control-wrapper:hover{-webkit-box-shadow:none;box-shadow:none}.owc-expandable-group owc-expandable.owc-expandable--variant-filled.owc-expandable--disabled{-webkit-box-shadow:none;box-shadow:none;border-bottom-color:var(--one-color-interaction-disabled-intensity-low)}.owc-expandable-group owc-expandable:first-of-type{border-top-right-radius:inherit;border-top-left-radius:inherit}.owc-expandable-group owc-expandable:last-of-type{border-bottom-right-radius:inherit;border-bottom-left-radius:inherit;border-bottom:none}";

const EXPANDABLE_COMPONENT_TAG = 'owc-expandable';
const ExpandableGroup = /*@__PURE__*/ proxyCustomElement(class ExpandableGroup extends HTMLElement {
  constructor() {
    super();
    this.__registerHost();
    this.expandedElementsChanged = createEvent(this, "expandedElementsChanged", 3);
    this.component = new ComponentUtils(this);
    this.multiple = false;
    this.expanded = [];
    this.allDisabled = false;
  }
  onExpandedChange(event) {
    stopEvent(event);
    const element = findElementInEventPath(event, EXPANDABLE_COMPONENT_TAG);
    const elementIndex = this.getExpandableElements().findIndex(el => el === null || el === void 0 ? void 0 : el.isSameNode(element));
    elementIndex >= 0 && this.toggleExpandedState(elementIndex, event.detail);
  }
  onExpandedChanged(newValue, oldValue) {
    if (!isSameArray(newValue, oldValue)) {
      this.getExpandableElements().forEach((el, index) => {
        el.expanded = arrayIncludes(newValue, index);
      });
      this.expandedElementsChanged.emit(newValue);
    }
  }
  /** Method to expand/collapse all expandable elements within the group */
  async setAllState(state) {
    this.getExpandableElements().forEach((item, index) => {
      if (!item.disabled) {
        item.expanded = state;
        this.toggleExpandedState(index, state);
      }
    });
    return Promise.resolve();
  }
  componentDidLoad() {
    const expandedElements = this.getExpandableElements()
      .map((el, i) => (el.expanded ? i : -1))
      .filter(index => index >= 0);
    this.expanded = expandedElements.length ? (this.multiple ? expandedElements : expandedElements.slice(-1)) : [];
  }
  componentWillRender() {
    const items = this.getExpandableElements();
    this.allDisabled = items.length && items.every(item => !!item.disabled);
  }
  getExpandableElements() {
    return getSiblings(this.hostElement.querySelector(EXPANDABLE_COMPONENT_TAG), el => el.tagName.toLowerCase() === EXPANDABLE_COMPONENT_TAG);
  }
  toggleExpandedState(elementIndex, expanded) {
    if (expanded) {
      if (this.multiple) {
        !arrayIncludes(this.expanded, elementIndex) && (this.expanded = [...this.expanded, elementIndex].sort((a, b) => a - b));
      }
      else {
        this.expanded = [elementIndex];
      }
    }
    else {
      this.expanded = this.expanded.filter(item => item !== elementIndex);
    }
  }
  render() {
    const hostClasses = this.component.getHostBemClasses({ multiple: this.multiple });
    return (h(Host, Object.assign({ class: hostClasses }, accessibility({ role: 'group', aria: { disabled: this.allDisabled, multiselectable: this.multiple } })), h("slot", null)));
  }
  get hostElement() { return this; }
  static get watchers() { return {
    "expanded": ["onExpandedChanged"]
  }; }
  static get style() { return expandableGroupCss; }
}, [4, "owc-expandable-group", {
    "multiple": [516],
    "expanded": [32],
    "allDisabled": [32],
    "setAllState": [64]
  }, [[0, "expandedChange", "onExpandedChange"]]]);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-expandable-group"];
  components.forEach(tagName => { switch (tagName) {
    case "owc-expandable-group":
      if (!customElements.get(tagName)) {
        customElements.define(tagName, ExpandableGroup);
      }
      break;
  } });
}

const OwcExpandableGroup = ExpandableGroup;
const defineCustomElement = defineCustomElement$1;

export { OwcExpandableGroup, defineCustomElement };
